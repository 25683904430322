<template>
  <v-app id="main-layout">

    <NodesTopBar v-if="!showingPodsLayout">
    </NodesTopBar>

    <PodsTopBar v-if="showingPodsLayout">
    </PodsTopBar>

    <snackbars>
    </snackbars>

    <!-- TODO: Show this component only on the /nodes/:id route or the /search -->
    <NodeSidemenu v-if="!showingPodsLayout">
    </NodeSidemenu>

    <PodSidemenu v-if="showingPodsLayout">
    </PodSidemenu>

    <NodeMovedDialog v-model="shouldShowMovedNodeDialog">
    </NodeMovedDialog>

    <NodeDeletedDialog v-model="shouldShowDeletedNodeDialog">
    </NodeDeletedDialog>

    <v-main>
      <v-container fluid  style="height: 100%; padding-top: 0px; ">
        <router-view>
        </router-view>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import isEmpty from 'lodash/isEmpty'

import NodesTopBar from "./components/topBars/NodesTopBar.vue"
import PodsTopBar from "./components/topBars/PodsTopBar.vue"
import Snackbars from "../components/Snackbars.vue"
import LayoutThemeMixin from '../components/LayoutThemeMixin.js'
import NodeSidemenu from "./components/nodeSidemenu/NodeSidemenu.vue"
import PodSidemenu from "./components/podSidemenu/PodSidemenu.vue"

import NodeMovedDialog from '../../routes/nodes2/components/dialogs/NodeMovedDialog.vue'
import NodeDeletedDialog from '../../routes/nodes2/components/dialogs/NodeDeletedDialog.vue'


export default {
  name: 'MainLayout',
  mixins: [LayoutThemeMixin ],
  components: {
    NodesTopBar,
    PodsTopBar,
    Snackbars,
    NodeSidemenu,
    PodSidemenu,

    NodeMovedDialog,
    NodeDeletedDialog,
  },
  data() {
    return {
      shouldShowMovedNodeDialog: false,
      shouldShowDeletedNodeDialog: false,
    };
  },
  mounted() {
    // // set the initial state of the dark mode
    // this.$vuetify.theme.dark = this.isDarkMode;
    let self = this;

    const REFRESHDBUSER = this.$store.getters.keywords.AUTH.REFRESHDBUSER;
    this.$store.dispatch(REFRESHDBUSER)
      .then((user) => {
        // set the initial state of the theme
        const isDarkMode = user?.settings?.theme === 'dark';

        const SETDARKMODE = self.$store.getters.keywords.MAIN.SETDARKMODE;
        self.$store.commit(SETDARKMODE, isDarkMode);

        // set the initial state of the folder view
        const nodeView = (user?.settings?.view_folders) === 'list' ? 'list' : 'grid';

        const SETNODEVIEW = self.$store.getters.keywords.NODE.SETNODEVIEW;
        self.$store.commit(SETNODEVIEW, nodeView);

        // On startup the abilities are updated in the App.vue file
        // if (window.localStorage.abilities) {
        //   const abilities = JSON.parse(window.localStorage.abilities);
        //   self.$ability.update(abilities);
        // }
      })
      .then(this.setSubscriptionTimeout)
      .catch((error) => {
        console.log("ERROR:", error);
        setTimeout(() => {
          self.$router.push('/account/logout');
        }, 2000);
      });

    const SUBSCRIBE = this.$store.getters.keywords.NOTIFICATION.SUBSCRIBE;
    this.$store.dispatch(SUBSCRIBE);

    const REFRESHLANGUAGES = this.$store.getters.keywords.COMMON.REFRESHLANGUAGES;
    this.$store.dispatch(REFRESHLANGUAGES);

    const REFRESHACCESSPOINTS = this.$store.getters.keywords.NODE.REFRESHACCESSPOINTS;
    this.$store.dispatch(REFRESHACCESSPOINTS);

    const REFRESHSERVICES = this.$store.getters.keywords.SERVICE.REFRESHSERVICES;
    this.$store.dispatch(REFRESHSERVICES);
  },
  beforeDestroy: function() {
    // remove subscription check timeout
    clearTimeout(this.subscriptionTimeoutID);
  },
  watch: {
    getDbUser(newUser) {
      if (newUser) {
        this.refreshSubscription();
      }
    },
    getRootNodeId(oldValue, newValue) {
      if (oldValue && newValue) {
        clearTimeout(this.subscriptionTimeoutID);
        this.checkSubscription();
      }
    },
    isMovedNodeDialog: function(newValue) {
      if (newValue) {
        this.shouldShowMovedNodeDialog = newValue;
      }
    },
    isDeletedNodeDialog: function(newValue) {
      if (newValue) {
        this.shouldShowDeletedNodeDialog = newValue;
      }
    },
  },
  methods: {
    setSubscriptionTimeout: function() {
      // Sets a timeout function to check if the subscription is still valid
      const SUBSCRIPTIONEXPIRETIMESTAMP = this.$store.getters.keywords.AUTH.SUBSCRIPTIONEXPIRETIMESTAMP;
      const expireTS = this.$store.getters[SUBSCRIPTIONEXPIRETIMESTAMP];
      console.log("EXPIRE TS:", expireTS);
      if (!expireTS) {
        return;
      }

      const maxTimeout = 2147483647; // 32-bit signied int, otherwise overflows
      const waitTime = Math.min(expireTS, maxTimeout);

      this.subscriptionTimeoutID = setTimeout(this.checkSubscription, waitTime);
      // setTimeout(this.checkSubscription, 10000);
    },
    checkSubscription: async function() {
      // Check if the subscription is still valid
      const REFRESHDBUSER = this.$store.getters.keywords.AUTH.REFRESHDBUSER;
      const REFRESHSUBSCRIPTION = this.$store.getters.keywords.SYSTEM.REFRESHSUBSCRIPTION;

      await this.$store.dispatch(REFRESHDBUSER);

      if (
        this.getRootNodeId &&
        this.isTenantExpired &&
        this.$canControlTenantConfig()
      ) {
        let subscription = await this.$store.dispatch(REFRESHSUBSCRIPTION, this.getRootNodeId);
        if (subscription && subscription.plans && !isEmpty(subscription.plans)) {
          this.$router.push('/console/subscription-plans');
          return;
        }
      }

      if (this.isTenantExpired) {
        console.log("Tenant is expired")
        this.$router.push('/account/tenant-expired');
      } else {
        console.log("tenant not expired");
        this.setSubscriptionTimeout();
      }
    },
    async refreshSubscription() {
      const REFRESHSUBSCRIPTION = this.$store.getters.keywords.SYSTEM.REFRESHSUBSCRIPTION;

      if (
        this.getRootNodeId &&
        this.$canControlTenantConfig())
      {
        let subscription = await this.$store.dispatch(REFRESHSUBSCRIPTION, this.getRootNodeId);
        subscription;
        // console.log("subscription", subscription)
      }
    },
  },
  computed: {
    showingPodsLayout() {
      return this.$route.path.startsWith("/pod/");
    },
    getDbUser() {
      const GETDBUSER = this.$store.getters.keywords.AUTH.GETDBUSER;
      return this.$store.getters[GETDBUSER];
    },
    isMovedNodeDialog: function() {
      const ISVIEWNODEMOVED = this.$store.getters.keywords.NODE.ISVIEWNODEMOVED;
      return this.$store.getters[ISVIEWNODEMOVED];
    },
    isDeletedNodeDialog: function() {
      const ISVIEWNODEDELETED = this.$store.getters.keywords.NODE.ISVIEWNODEDELETED;
      return this.$store.getters[ISVIEWNODEDELETED];
    },
    getRootNodeId() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    isTenantExpired() {
      const TENANTISEXPIRED = this.$store.getters.keywords.AUTH.TENANTISEXPIRED;
      return this.$store.getters[TENANTISEXPIRED];
    },
  }
}
</script>
