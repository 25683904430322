import { API } from 'aws-amplify';

export default {
  getMe: function() {
    const options = {};
    return API.get('app', 'me', options);
  },

  getAllUsers: function(payload = {}) {
    const options = {};

    return API.get('app', `tenants/${payload.accessPointId}/users`, options);
  },

  getUsertypes: function() {
    const options = {};
    return API.get('app', 'usertypes', options);
  },

  createUser: function(userParams) {
    const options = {
        // headers, body parameters, query parameters
        body: userParams
    };
    return API.post('app', 'users', options);
  },

  updateUser: function(userParams) {
    const options = {
        // headers, body parameters, query parameters
        body: userParams
    };
    return API.patch('app', 'users/' + userParams.id, options);
  },

  deleteUser: function(payload) {
    const options = {};
    return API.del('app', `users/${payload.userId}`, options);
  },

  detachUser: function(payload) {
    const options = {};
    return API.del('app', `tenants/${payload.accessPointId}/users/${payload.userId}`, options);
  },

  savePermissions: function(payload) {
    const options = {
      body: payload.permissions,
    };
    return API.patch('app', `tenants/${payload.accessPointId}/users/${payload.userId}/permissions`, options);
  },

  saveSettings: function(id, settings) {
    const options = {
      body: settings,
    };
    return API.patch('app', 'users/' + id + '/settings', options);
  },

  changeRootNode: function(nodeid, users) {
    const options = {
      body: {
        nodeid,
        users,
      },
    };

    return API.patch('app', 'users/folders', options);
  },
}
