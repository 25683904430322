<template>
  <v-app id="console-layout">
    <top-bar>
    </top-bar>

    <snackbars>
    </snackbars>

    <ConsoleSidemenu v-bind:subscriptionType="subscriptionType" v-bind:subscriptionName="subscriptionName">
    </ConsoleSidemenu>

    <v-main>
      <v-container fluid style="padding-top: 0px;">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "./TopBar.vue"
import Snackbars from "../components/Snackbars.vue"
import ConsoleSidemenu from "./ConsoleSidemenu.vue"
import LayoutThemeMixin from '../components/LayoutThemeMixin.js'

export default {
  name: "ConsoleLayout",
  mixins: [LayoutThemeMixin],
  components: {
    TopBar,
    Snackbars,
    ConsoleSidemenu,
  },
  mounted() {
    // this.getCurrentNode;
    this.refreshDbUser();
    this.setActiveNodeIfNeeded();

    const REFRESHACCESSPOINTS = this.$store.getters.keywords.NODE.REFRESHACCESSPOINTS;
    this.$store.dispatch(REFRESHACCESSPOINTS)

    this.refreshSubscription();

    const SUBSCRIBE = this.$store.getters.keywords.NOTIFICATION.SUBSCRIBE;
    this.$store.dispatch(SUBSCRIBE);
  },
  watch: {
    /*
    getDbUser(newUser) {
      if (newUser) {
        this.refreshSubscription();
      }
    },
    */
    getRootNodeId(oldValue, newValue) {
      if (oldValue && newValue) {
        this.refreshSubscription();
      }
    },
    getAccessPoints() {
      this.setActiveNodeIfNeeded();
    },
  },
  methods: {
    refreshDbUser() {
      let self = this;
      const REFRESHDBUSER = this.$store.getters.keywords.AUTH.REFRESHDBUSER;
      this.$store.dispatch(REFRESHDBUSER)
        .then((user) => {
          const isDarkMode = user?.settings?.theme === 'dark';

          const SETDARKMODE = self.$store.getters.keywords.MAIN.SETDARKMODE;
          self.$store.commit(SETDARKMODE, isDarkMode);

          // if (window.localStorage.abilities) {
          //   const abilities = JSON.parse(window.localStorage.abilities);
          //   self.$ability.update(abilities);
          // }
        })
        .then(self.setSubscriptionTimeout)
        .catch((error) => {

          console.log("ERROR:", error);
          setTimeout(() => {
            self.$router.push('/account/logout');
          }, 2000);
        });
    },
    setActiveNodeIfNeeded() {
      // When we refresh any page on the layout we are losing the active node.
      // So we set the access point as active node.
      if (!this.getCurrentNode &&
          this.getRootNodeId &&
          this.getAccessPoints &&
          this.getAccessPoints.length > 0 )
      {
        const SETACTIVENODE = this.$store.getters.keywords.NODE.SETACTIVENODE;
        this.$store.dispatch(SETACTIVENODE, this.getRootNodeId);
      }
    },
    async refreshSubscription() {
      const REFRESHSUBSCRIPTION = this.$store.getters.keywords.SYSTEM.REFRESHSUBSCRIPTION;

      if (this.getRootNodeId && this.$canControlTenantConfig()) {
        let subscription = await this.$store.dispatch(REFRESHSUBSCRIPTION, this.getRootNodeId);
        subscription;
        // console.log("subscription", subscription)
      }
    },
    async checkSubscription() {
      const allowedUrls = [
        '/console/index',
        '/console/subscription',
        '/console/subscription-plans'
      ];

      // Check if the subscription is still valid
      if (this.isTenantExpired && !allowedUrls.includes(this.$route.path)) {
        this.$router.push('/console/subscription-plans');
      }
    },
    setSubscriptionTimeout: function() {
      // Sets a timeout function to check if the subscription is still valid
      const SUBSCRIPTIONEXPIRETIMESTAMP = this.$store.getters.keywords.AUTH.SUBSCRIPTIONEXPIRETIMESTAMP;
      const expireTS = this.$store.getters[SUBSCRIPTIONEXPIRETIMESTAMP];
      if (!expireTS) {
        return;
      }

      const maxTimeout = 2147483647; // 32-bit signied int, otherwise overflows
      const waitTime = Math.min(expireTS, maxTimeout);

      this.subscriptionTimeoutID = setTimeout(this.checkSubscription, waitTime);
    },
  },
  computed: {
    getDbUser() {
      const GETDBUSER = this.$store.getters.keywords.AUTH.GETDBUSER;
      return this.$store.getters[GETDBUSER];
    },
    getAccessPoints() {
      const ACCESSPOINTS = this.$store.getters.keywords.NODE.ACCESSPOINTS;
      return this.$store.getters[ACCESSPOINTS];
    },
    getRootNodeId() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    getCurrentNode: function() {
      const GETCURRENTNODE = this.$store.getters.keywords.NODE.GETCURRENTNODE;
      return this.$store.getters[GETCURRENTNODE];
    },
    isTenantExpired: function() {
      const TENANTISEXPIRED = this.$store.getters.keywords.AUTH.TENANTISEXPIRED;
      return this.$store.getters[TENANTISEXPIRED];
    },
    subscriptionType: function() {
      const GETACTIVETENANT = this.$store.getters.keywords.AUTH.GETACTIVETENANT;
      const activeTenant = this.$store.getters[GETACTIVETENANT];

      if (activeTenant?.subscription?.subscriptionType) {
        return activeTenant.subscription.subscriptionType;
      }

      return 'enterprise';
    },
    subscriptionName() {
      const GETSUBSCRIPTION = this.$store.getters.keywords.SYSTEM.GETSUBSCRIPTION;
      const getSubscription = this.$store.getters[GETSUBSCRIPTION];

      return getSubscription?.subscription?.name || '';
    },
  },
}
</script>

<style>
#console-layout main {
  margin-top: 80px;
}
</style>
