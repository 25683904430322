<template>
  <v-dialog
  v-model="dialog"
  persistent
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly"
  :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
  style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="podFiles-tree-actions-renameAsset-activator">
        <slot></slot>
      </div>
    </template>


    <v-card>
      <v-card-title class="primary d-flex justify-space-between">
        <span>{{$t('routes.pod.renameAssetDialogTitle')}}</span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-form ref="form" v-on:submit.prevent="formSubmit()" data-cy="dialog-renameAsset-modal">
        <v-card-text class="pb-0">
          {{$t('routes.pod.renameAssetDialogFilename')}}:
          <v-text-field
            v-model="filename"
            v-bind:rules="filenameRules"
            v-bind:label="$t('routes.pod.renameAssetDialogFilename')"
            required block
            solo flat
            data-cy="dialog-renameAsset-assetName"
            >
          </v-text-field>

          <div v-if="errorMessage" class="error" data-cy="dialog-renameAsset-error">
            {{errorMessage}}
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text type="submit" data-cy="dialog-renameAsset-ok">{{$t('routes.pod.renameAssetDialogOk')}}</v-btn>
          <v-btn text @click="dialog = false" data-cy="dialog-renameAsset-false">{{$t('routes.pod.renameAssetDialogCancel')}}</v-btn>
        </v-card-actions>
      </v-form>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RenameAssetDialog",
  props: {
    pod: {
      type: Object,
      required: true,
    },
    asset: {
      type: Object,
      required: false
    }
  },
  data: function() {
    return {
      dialog: false,

      filename: this.asset.name || "",
      filenameRules: [
        v => !!v || this.$t('routes.pod.renameAssetDialogInvalidName'),
      ],

      errorMessage: "",
    };
  },
  watch: {
    dialog: function(newValue) {
      if (newValue) {
        this.filename = this.asset.name;
        this.errorMessage = "";
      }
    }
  },
  methods: {
    formSubmit: function() {
      const validForm = this.$refs.form.validate();
      this.errorMessage = "";
      if (!validForm) {
        return;
      }

      let data = {
        podID: this.pod.id,
        assetID: this.asset.id,
        payload: {
          name: this.filename,
        }
      };

      let self = this;
      const UPDATEPODASSET = this.$store.getters.keywords.POD.UPDATEPODASSET;
      this.$store.dispatch(UPDATEPODASSET, data)
      .then(function() {
        self.dialog = false;
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          self.errorMessage = error.response.data.error.message;
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    },
  }
}
</script>

<style>

</style>
