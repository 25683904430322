import { API } from 'aws-amplify';


export default {
  getAccessPoints: function() {
    const options = {
      // headers, body parameters, query parameters
    };

    return API.get('app', 'nodes', options);
  },

  getNodeByID: function(id) {
    const options = {
        // headers, body parameters, query parameters
    };
    return API.get('app', 'nodes/' + id, options);
  },

  createNode: function(params) {
    let formData = new FormData();

    for ( var key in params ) {
        formData.append(key, params[key]);
    }

    const options = {
      // headers, body parameters, query parameters
      headers: {
        "Content-Type": "multipart/form-data; boundary=file",
      },
      body: formData
    };
    return API.post('app', 'nodes', options);
  },

  updateNode: function(params, id) {
    let formData = new FormData();

    for (const key in params ) {
      //This is done because FormData casts null to the string "null", better to not send key-value pair in this case
      if (params[key] !== null) {
        formData.append(key, params[key]);
      }
    }

    params.id = id;
    const options = {
      headers: {
        "Content-Type": "multipart/form-data; boundary=file",
      },
      body: formData
    };
    return API.patch('app', 'nodes/' + id, options);
  },

  updateFolder: function(id, data) {
    const options = {
      body: data
    };

    return API.patch('app', 'nodes/' + id + '/folders', options);
  },

  deleteNode: function(id) {
    const options = {};
    return API.del('app', 'nodes/' + id, options);
  },

  deleteLogo: function(id) {
    const options = {};
    return API.del('app', '/nodes/'+id+'/logo', options);
  },

  softDeleteTenant: function(id) {
    const options = {};
    return API.del('app', 'tenants/' + id + '/soft', options);
  },

  getUsers: function(nodeID) {
    return API.get('app', 'nodes/' + nodeID + '/users/');
  },

  getSubnodes: function(nodeID, subnodesType) {
    let url = 'nodes/'+nodeID+'/subnodes';
    if (subnodesType !== null) {
      url += '/'+subnodesType;
    }

    const options = {};

    return API.get('app', url, options);
  },

  moveManyNodes: function(data) {
    const options = {
      body: data,
    };
    return API.patch('app', 'nodes/move', options);
  },

  copyManyNodes: function(data) {
    const options = {
      body: data,
    };
    return API.post('app', 'nodes/copy', options);
  },

  updateNote: function(params, id) {
    const options = {
      body: {
        note: params.note
      }
    };

    return API.patch('app', 'nodes/' + id + '/note', options);
  },

  updateProjectStatus: function(params, id) {
    const options = {
      body: params,
    }

    return API.patch('app', 'nodes/'+ id + '/projectstatus', options);
  },

  renameNode: function(params, id) {
    const options = {
      body: {
        name: params.name
      }
    };

    return API.patch('app', 'nodes/' + id + '/name', options);
  },

  getProperties: function(nodeID) {
    const options = {};
    return API.get('app', 'nodes/' + nodeID + '/properties', options);
  }
};
