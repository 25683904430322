<template>
  <!-- <comcomponent v-bind:is="layout">
    <router-view v-bind:key="$route.fullPath"></router-view>
  </comcomponent> -->

  <div id="app" style="width: 100%;">
    <MainLayout v-if="this.$route.meta.layout==='MainLayout'"></MainLayout>
    <LoginLayout v-if="this.$route.meta.layout==='LoginLayout'"></LoginLayout>
    <ConsoleLayout v-if="this.$route.meta.layout==='ConsoleLayout'"></ConsoleLayout>
    <LoadingLayout v-if="this.$route.meta.layout==='LoadingLayout'"></LoadingLayout>
    <!-- <ExternalLayout v-if="this.$route.meta.layout==='ExternalLayout'"></ExternalLayout> -->
    <BlankLayout v-if="this.$route.meta.layout==='BlankLayout'"></BlankLayout>
  </div>


</template>

<script>

// import { AmplifyEventBus } from 'aws-amplify-vue'
// import { Auth } from 'aws-amplify'

import MainLayout from './layouts2/mainLayout/MainLayout.vue'
import LoginLayout from './layouts2/loginLayout/LoginLayout.vue'
import ConsoleLayout from './layouts2/consoleLayout/ConsoleLayout.vue'
import LoadingLayout from './layouts2/LoadingLayout.vue'
// import ExternalLayout from './layouts2/ExternalLayout.vue'
import BlankLayout from './layouts2/BlankLayout.vue'

export default {
  name: 'App',
  components: {
    MainLayout,
    LoginLayout,
    ConsoleLayout,
    LoadingLayout,
    // ExternalLayout,
    BlankLayout,
  },
  computed: {
    // layout: function() {
    //   const GETLAYOUT = this.$store.getters.keywords.MAIN.GETLAYOUT;
    //   return this.$store.getters[GETLAYOUT];
    // }
  },
  created: async function() {
    if (window.localStorage.abilities) {
      const abilities = JSON.parse(window.localStorage.abilities);
      this.$ability.update(abilities);
    }

    // try {
    //   this.user = await Auth.currentAuthenticatedUser();
    //   if (this.user && this.$canListNodeAndPod()) {
    //     const REFRESHACCESSPOINTS = this.$store.getters.keywords.NODE.REFRESHACCESSPOINTS;
    //     this.$store.dispatch(REFRESHACCESSPOINTS);
    //   }
    // } catch (e) {
    //   // Do nothing
    // }

    if (window.localStorage.abilities) {
      const abilities = JSON.parse(window.localStorage.abilities);
      this.$ability.update(abilities);
    }

    const interfaceLanguage = window.localStorage.getItem('languageInterface');
    if (interfaceLanguage) {
      const language = interfaceLanguage || 'en';
      this.$i18n.locale = language;
    }
  },
  mounted: async function() {
    await this.$recaptchaLoaded()
    const recaptcha = this.$recaptchaInstance;

    // Hide reCAPTCHA badge:
    recaptcha.hideBadge();
  }
}
</script>

<style>
</style>
