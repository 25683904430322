<template>
  <div>
    <div v-if="!pod">
      File not found!
    </div>

    <div v-if="pod">
      <div class="d-flex align-center" data-cy="podMenu-transcript-form">
        <div class="flex-grow-1" data-cy="podMenu-transcript-title">
          <h1>{{$t('routes.pod.transcripts')}}</h1>
        </div>

        <div>
          <TranscribeSelectedDialog
            v-if="showingCreateTranscriptBtn"
            v-bind:pods="Array(pod)"
            @refreshSelectedTranscript="refreshSelectedTranscript"
            >
            <v-btn color="primary" elevation=0  data-cy="podMenu-transcript-newTranscript-btn">
              <v-icon>mdi-plus-circle</v-icon>
              {{$t('routes.pod.newTranscript')}}
            </v-btn>
          </TranscribeSelectedDialog>

        </div>
      </div>

      <div class="mb-4" data-cy="podMenu-transcript-selectTranscript">
        <v-radio-group
          v-model="selectedTranscriptId"
          :label="$t('routes.pod.menuTranscript.selectTranscript')"
          v-bind:hint="getTranscriptHint"
          mandatory
        >
          <table>
            <tr v-for="transcript in getTranscripts" :key="transcript.id">
              <td>
                <v-radio
                  :label="transcript.name"
                  :value="transcript.id"
                  data-cy="podMenu-transcript-fileName">
                </v-radio>
              </td>
              <td class="text-right">
                <TranscriptContextMenu
                  :transcript="transcript"
                  @refreshSelectedTranscript="refreshSelectedTranscript"
                >
                  <v-btn tile icon data-cy="podMenu-transcript-actionsButton">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </TranscriptContextMenu>
              </td>
            </tr>
          </table>

        </v-radio-group>
        {{getTranscriptHint}}
      </div>


      <div class="mt-4 d-flex justify-space-between">
        <div class="d-flex" style="width: 66%;">
          <div style="width: 50%;">
            {{$t('routes.pod.menuTranscript.confidenceThreshold')}}
          </div>

          <div class="pt-1" style="width: 50%;">
            <v-text-field
              type="number"
              v-model="confidenceLevel"
              v-bind:disabled="subtitles.length == 0"
              v-on:blur="confidenceFocusOut"
              v-bind:rules="confidenceLevelRules"
              v-bind:label="$t('routes.pod.menuTranscript.confidenceThreshold')"
              width="100%"
              max="100" min="0"
              hide-details="auto"
              required dense
              solo flat
              data-cy="podMenu-transcript-confidenceLevel">
            </v-text-field>
          </div>
        </div>

        <div v-if="$canControlPodService() && !showForm" class="text-right" style="width: 33%;">
          <v-btn
            v-on:click="toggleAddForm()"
            v-bind:disabled="!selectedTranscript"
            color="primary"
            elevation=0
            data-cy="podMenu-transcript-addSubtitles-btn">
            <v-icon>mdi-file-edit</v-icon>
            {{$t('routes.pod.menuTranscript.newLine')}}
          </v-btn>
        </div>
      </div>

      <div v-if="showForm && editItem == null" class="mt-2">
        <EditTranscriptForm
          v-bind:pod.sync="pod"
          v-bind:selectedTranscript.sync="selectedTranscript"
          v-on:visibility="showForm = $event"
          >
        </EditTranscriptForm>
      </div>

      <!-- The subtitles table headers -->
      <div v-if="subtitles.length > 0">
        <table class="mt-2 pod-transcript-table" data-cy="podMenu-transcript-subtitleTable">
          <thead>
            <tr>
              <td>{{$t('routes.pod.menuTranscript.inTimeString')}}</td>
              <td>{{$t('routes.pod.menuTranscript.durationShort')}}</td>
              <td>{{$t('routes.pod.menuTranscript.lines')}}</td>
            </tr>
          </thead>
        </table>
      </div>


      <!-- list of subtitles scroll bar start -->
      <div class="pod-transcript-scroll scrollable">

        <table class="mt-2 pod-transcript-table" data-cy="podMenu-transcript-subtitleTable">
          <!-- <thead>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </thead> -->
          <tbody>
            <template v-for="subItem in subtitles">
              <tr
                v-if="!isEditItem(subItem)"
                v-bind:key="subItem.id"
                v-bind:class="isActiveSubtitle(subItem)"
                v-on:click="subtitleClick(subItem)"
                v-on:contextmenu.prevent="contextMenuClick(subItem, $event)"
                ref="subtitleLineRef"
                data-cy="podMenu-transcript-subtitleRow"
                >

                <td data-cy="podMenu-transcript-subtitle-start">
                  {{subItem.start | toTimeMilliseconds}}
                </td>
                <td data-cy="podMenu-transcript-subtitle-duration">
                  <v-tooltip bottom z-index="2000" open-delay="200">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" v-bind:class="isDurationTooLong(subItem)">
                        {{subItem.duration}}{{$t('routes.pod.menuTranscript.secondsShorter')}}
                      </span>
                    </template>
                    <span>{{subItem.charsPerSec}} {{$t('routes.pod.menuTranscript.chsec')}}</span>
                  </v-tooltip>
                </td>
                <td v-html="processItem(subItem)" style="white-space: pre-wrap;" data-cy="podMenu-transcript-subtitle-lines"><!-- this should be on a single line -->
                </td>
              </tr>
              <tr v-bind:key="subItem.id" v-if="isEditItem(subItem)">
                <td colspan="4">
                  <edit-transcript-form
                    v-bind:pod="pod"
                    v-bind:editItem="subItem"
                    v-bind:selectedTranscript.sync="selectedTranscript"
                    v-on:visibility="showForm = $event"
                    >
                  </edit-transcript-form>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

      </div>
      <!-- scroll bar ends -->

      <v-menu
        v-model="showContextMenu"
        :position-x="positionX"
        :position-y="positionY"
        v-bind:close-on-content-click="false"
        close-on-click
        offset-y bottom
        absolute
        z-index="20"
      >
        <v-list data-cy="list-podItem-actions-list">
          <v-list-item @click="contextMenuEditLine()">
            <v-list-item-icon>
              <v-icon small>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-content data-cy="">
              <v-list-item-title>{{$t('routes.pod.menuTranscript.editLine')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="contextMenuDeleteLine()">
            <v-list-item-icon>
              <v-icon small>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content data-cy="">
              <v-list-item-title>{{$t('routes.pod.menuTranscript.deleteLine')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>



    </div>
  </div>
</template>

<script>
import MimetypeHelper from '../../../../../components/MimetypeHelper.js'
import TranscribeSelectedDialog from "../../../../../routes/nodes2/components/dialogs/TranscribeSelectedDialog.vue"
import TranscriptContextMenu from './TranscriptContextMenu.vue'
import EditTranscriptForm from './EditTranscriptForm.vue'

export default {
  name: "TranscriptsToolbar",
  components: {
    TranscribeSelectedDialog,
    TranscriptContextMenu,
    EditTranscriptForm,
  },
  data() {
    return {
      selectedTranscriptId: null,

      editItem: null,
      showForm: false,

      confidenceLevel: 90,
      confidenceLevelRules: [
        // v => !isNaN(v) || "Invalid number!"
      ],

      showContextMenu: false,
      positionX: 0,
      positionY: 0,
      transcriptLineItem: null,
    };
  },
  watch: {
    playerCurrentTime: function(time) {
      time;
      // console.log("Time updated", time);
      this.scrollToActiveLine();
    },
    selectedTranscript: function(newTranscript) {
      this.editItem = null;
      this.showForm = false;

      if (!newTranscript) {
        return;
      }

      // change the selected subtitle in the video player
      for (let i = 0; i < this.availableSubtitles.length; i++) {
        if (
            this.availableSubtitles[i].isTranscript &&
            this.availableSubtitles[i].transcriptId == newTranscript.id
        ) {
          const SETACTIVESUBTITLE = this.$store.getters.keywords.PLAYER.SETACTIVESUBTITLE;
          this.$store.commit(SETACTIVESUBTITLE, this.availableSubtitles[i]);
        }
      }
    },
    activeSubtitle: function(newSubtitle) {
      if (!newSubtitle || !newSubtitle.isTranscript) return;

      for (var i = 0; i < this.getTranscripts.length; i++) {
        if (newSubtitle.transcriptId == this.getTranscripts[i].id) {
          this.selectedTranscript = this.getTranscripts[i];
        }
      }
    }
  },
  methods: {
    processItem: function(subItem) {
      const confidenceLevel = this.confidenceLevel / 100;
      let content = subItem.content;

      if (subItem.mldata && subItem.mldata.length > 0) {
        const isPunctuation = (str) => /^[.,!?]$/.test(str);

        const words = new Map();
        for (const mlData of subItem.mldata) {
          if (
              !isPunctuation(mlData.mldata.content) &&
              !words.has(mlData.mldata.content) &&
              mlData.mldata.confidence < confidenceLevel
          ) {
            words.set(mlData.mldata.content, {
              content: mlData.mldata.content,
              confidence: mlData.mldata.confidence
            });
          }
        }

        if (words.size > 0) {
          for (const word of words.values()) {
            content = content.split(word.content).join(`<span class='pod-subtitle-warning'>${word.content}</span>`);
          }
        }
      }

      return content;
    },
    toggleAddForm: function() {
      if (this.showForm && this.editItem) {
        this.editItem = null;
        return;
      }

      this.showForm = !this.showForm;
      this.editItem = null;
    },
    subtitleClick: function(item) {
      const SETSEEKTIMEEVENT = this.$store.getters.keywords.PLAYER.SETSEEKTIMEEVENT;
      this.$store.commit(SETSEEKTIMEEVENT, item.start);

      if (this.$canControlPodService()) {
        this.editItem = item;
        this.showForm = true;
      }
    },
    confidenceFocusOut: function() {
      if (isNaN(this.confidenceLevel) || this.confidenceLevel > 100 || this.confidenceLevel == "") {
        this.confidenceLevel = 100;
      } else if (this.confidenceLevel < 0) {
        this.confidenceLevel = 0;
      }
    },
    videotimeToInt: function(value) {
      let minutes = value.split(":")[0];
      let seconds = value.split(".")[0].split(":")[1];
      let milliseconds = value.split(".")[1];

      milliseconds = parseInt(parseFloat("0." + milliseconds) * 1000);
      seconds = parseInt(seconds) * 1000;
      minutes = parseInt(minutes) * 60000;
      return minutes + seconds + milliseconds;
    },
    refreshSelectedTranscript: function(transcript) {
      if (!transcript) {
        this.selectedTranscript = null;
        return;
      }

      for (let i = 0; i < this.getTranscripts.length; i++) {
        if (this.getTranscripts[i].id == transcript.id) {
          this.selectedTranscript = this.getTranscripts[i];
          break;
        }
      }
    },
    scrollToActiveLine: function() {
      if (!this.$refs.subtitleLineRef) {
        return;
      }

      for(let i = 0; i < this.$refs.subtitleLineRef.length; i ++) {
        const classList = Array.from(this.$refs.subtitleLineRef[i].classList);
        if (classList.indexOf('active-subtitle') >= 0) {
          this.$refs.subtitleLineRef[i].scrollIntoView(true);
          break;
        }
      }
    },
    contextMenuClick(item, event) {

      if (this.$canControlPodService()) {
        this.transcriptLineItem = item;
        this.positionX = event.clientX;
        this.positionY = event.clientY;
        this.$nextTick(() => {
          this.showContextMenu = true;
        });
      }

      const SETSEEKTIMEEVENT = this.$store.getters.keywords.PLAYER.SETSEEKTIMEEVENT;
      this.$store.commit(SETSEEKTIMEEVENT, item.start);
    },
    contextMenuEditLine() {
      this.showContextMenu = false;

      if (this.$canControlPodService()) {
        this.editItem = this.transcriptLineItem;
        this.showForm = true;
      }
    },
    contextMenuDeleteLine() {
      this.showContextMenu = false;

      if (!this.transcriptLineItem) {
        return;
      }

      const payload = {
        podID: this.pod.id,
        transcriptID: this.selectedTranscriptId,
        subtitleID: this.transcriptLineItem.id,
      };

      const DELETETRANSCRIPTLINE =  this.$store.getters.keywords.POD.TRANSCRIPT.DELETETRANSCRIPTLINE;
      this.$store.dispatch(DELETETRANSCRIPTLINE, payload);
    },
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    getTranscripts: function() {
      const GETTRANSCRIPTS = this.$store.getters.keywords.POD.TRANSCRIPT.GETTRANSCRIPTS;
      return this.$store.getters[GETTRANSCRIPTS];
    },
    selectedTranscript: {
      get() {
        return this.getTranscripts.find(x => x.id == this.selectedTranscriptId);
      },
      set(value) {
        this.selectedTranscriptId = value.id;
      },
    },
    getServices: function() {
      const GETALLSERVICES = this.$store.getters.keywords.SERVICE.GETALLSERVICES;
      return this.$store.getters[GETALLSERVICES];
    },
    subtitles: function() {
      if (this.selectedTranscript && this.selectedTranscript.sentences) {
        return this.selectedTranscript.sentences;
      }
      return [];
    },
    getTranscriptHint: function() {
      if (!this.selectedTranscript) return '';
      let responseText = '';

      for (let i = 0; i < this.getServices.length; i++) {
        if (this.getServices[i].id == this.selectedTranscript.serviceid) {
          responseText += this.getServices[i].name + ", ";
        }
      }

      responseText += this.$options.filters.isoToDatetime(this.selectedTranscript.createdat);
      return responseText;
    },
    playerCurrentTime: function() {
      const CURRENTTIME = this.$store.getters.keywords.PLAYER.CURRENTTIME;
      return this.$store.getters[CURRENTTIME];
    },
    isActiveSubtitle: function() {
      return function(item) {
        return {
            'active-subtitle':
                (this.playerCurrentTime >= item.start &&
                this.playerCurrentTime < item.end),
        };
      }
    },
    isEditItem: function() {
      return function(item) {
        return this.showForm && this.editItem && this.editItem.id == item.id;
      }
    },
    isDurationTooLong: function() {
      return function(item) {
        let charsPerSec = item.content.length / item.duration;
        let problematicDuration = (charsPerSec > 20) || (item.duration < 1) || (item.duration > 6);
        return {'pod-subtitle-warning': problematicDuration };
      };
    },
    availableSubtitles: function() {
      const AVAILABLESUBTITLES = this.$store.getters.keywords.PLAYER.AVAILABLESUBTITLES;
      return this.$store.getters[AVAILABLESUBTITLES];
    },
    activeSubtitle: function() {
      const GETACTIVESUBTITLE = this.$store.getters.keywords.PLAYER.GETACTIVESUBTITLE;
      return this.$store.getters[GETACTIVESUBTITLE];
    },

    isVideoPod: function() {
      return MimetypeHelper.isMimetypeVideo(this.pod);
    },
    isAudioPod: function() {
      return MimetypeHelper.isMimetypeAudio(this.pod);
    },
    showingCreateTranscriptBtn() {
      return this.isVideoPod || this.isAudioPod;
    }

  }
}
</script>

<style>


.pod-transcript-table { width: 100%; line-height: 1.2; border-collapse: collapse; }
.pod-transcript-table > thead { background-color: #FFC333; color: #131313;}
.pod-transcript-table > thead > tr > td { font-weight: 600; padding: 8px}
.pod-transcript-table > thead > tr > td:nth-child(2) { width: 10%;}

.pod-transcript-table tr > td:first-child { width: 20%; border-top-left-radius: 10px; border-bottom-left-radius: 10px;}
.pod-transcript-table tr > td:last-child { width: 70%; border-top-right-radius: 10px; border-bottom-right-radius: 10px;}

.theme--light .pod-transcript-table > tbody > tr {background-color: #f6f4f1; border-bottom: 2px solid #FFFFFF;}
.theme--dark .pod-transcript-table > tbody > tr {background-color: #2f3640; border-bottom: 2px solid #232b36;}

.pod-transcript-table > tbody > tr > td { padding: 8px}

.pod-transcript-table > tbody > tr.active-subtitle { color: #FFC333; }
.pod-transcript-table > tbody > tr > td > .pod-subtitle-warning { color: #ff8282; }



.pod-transcript-scroll {overflow-y: auto; flex-grow: 1; min-height: 100px;}


</style>
