<template>
  <div class="notification-list">
    <div v-for="item in displayedNotifications" :key="item.id" class="notification-item">
      <div class="notification-content" v-on:click="itemClicked(item)">
        <v-icon v-if="item.code == 'NM14'" size="14" class="pr-1">
          mdi-alert-circle-outline
        </v-icon>

        <span v-if="item.podid">
          <router-link :to="{ path: '/pod/' + item.podid }">
            {{$replaceT(item.code, item.params, item.content)}}
          </router-link>
        </span>
        <span v-else-if="item.nodeid">
          <router-link :to="{ path: '/node/' + item.nodeid }">
            {{$replaceT(item.code, item.params, item.content)}}
          </router-link>
        </span>
        <span v-else>
          {{$replaceT(item.code, item.params, item.content)}}
        </span>
      </div>
      <div class="notification-date">
        {{ timeAgo(item.createdat) }}
      </div>
    </div>
    <div v-if="hasMoreNotifications" class="load-more-container">
      <v-btn block elevation="0" class="secondaryButtons" @click="loadMoreNotifications">
        {{$t('routes.notifications.loadMoreNotifications')}}
      </v-btn>
    </div>
  </div>
</template>

<script>
// import NotificationsSelectBox from './NotificationsSelectBox'

export default {
  name: "NotificationsList",
  components: {
    // NotificationsSelectBox
  },
  data: function() {
    return {
      displayedNotifications: [], // Array to store currently displayed notifications
      perPage: 5, // Number of notifications to display per page
      currentPage: 1,
    };
  },
  watch: {
    notifications: function(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.displayedNotifications = this.notifications.slice(0, this.perPage);
        this.currentPage = 1;
      }
    }
  },
  methods: {
    itemClicked: function(item) {
      // check the item code and trigger an action
      const limitsCodes = [
        "reached-users-limit", "reached-clientuser-limit",
        "reached-storage-limit", "reached-bandwidth-limit",
        "reached-translation-limit", "reached-transcription-limit",
        "reached-rekognition-limit", "reached-credits-limit",
        "reached-resource-limit",
      ];

      if (limitsCodes.indexOf(item.code) >= 0) {
        this.showLimitsOnClick()
      }
    },

    showLimitsOnClick: function() {
      if (!this.$canControlTenantConfig()) {
        return;
      }

      this.$router.push(`/console/subscription-plans`);
    },

    loadMoreNotifications() {
      const start = this.currentPage * this.perPage;
      const end = start + this.perPage;

      this.displayedNotifications.push(...this.notifications.slice(start, end));
      this.currentPage++;
    },
    timeAgo(value) {
      if (!value) {
        return '';
      }

      const date = new Date(value);

      if (isNaN(date.getTime())) {
        return "Invalid date";
      }

      const seconds = Math.floor((new Date() - date) / 1000);

      let interval = seconds / 31536000;  // Number of seconds in a year
      if (interval >= 1) {
        const years = Math.floor(interval);
        return this.$tc('routes.notifications.yearsAgo', years);
      }

      interval = seconds / 2592000;  // Number of seconds in a month
      if (interval >= 1) {
        const months = Math.floor(interval);
        return this.$tc('routes.notifications.monthsAgo', months);
      }

      interval = seconds / 86400;  // Number of seconds in a day
      if (interval >= 1) {
        const days = Math.floor(interval);
        return this.$tc('routes.notifications.daysAgo', days);
      }

      interval = seconds / 3600;  // Number of seconds in an hour
      if (interval >= 1) {
        const hours = Math.floor(interval);
        return this.$tc('routes.notifications.hoursAgo', hours);
      }

      interval = seconds / 60;  // Number of seconds in a minute
      if (interval >= 1) {
        const minutes = Math.floor(interval);
        return this.$tc('routes.notifications.minutesAgo', minutes);
      }

      return this.$tc('routes.notifications.secondsAgo', seconds);
    }
  },
  computed: {
    notifications: function() {
      const GETNOTIFICATIONS = this.$store.getters.keywords.NOTIFICATION.GETNOTIFICATIONS;
      return this.$store.getters[GETNOTIFICATIONS];
    },
    hasMoreNotifications() {
      return this.notifications.length > this.displayedNotifications.length;
    },
  },
}
</script>

<style scoped>
.notification-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notification-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 4px; /* Rounded corners for a box-like appearance */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 1rem; /* Maintain spacing between notifications */
}

.notification-content {
  flex-grow: 1;
  line-break: anywhere;
}

.notification-date {
  color: #888;
  font-size: 0.8rem;
  align-self: flex-end;
  font-style: italic;
}

.load-more-container {
  text-align: center;
  margin-top: 1rem;
}
</style>
