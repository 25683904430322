<template>
  <v-menu
    v-model="menu"
    v-bind:close-on-content-click="false"

    bottom
    offset-y
    origin="right top"
    transition="fade-transition"
    style="overflow-y: auto; bottom: 0;"
    content-class="notifications-menu"
  >

    <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip bottom z-index="1200">
        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
          <v-btn
            v-bind="{...attrsMenu, ...attrsTooltip}" v-on="{...onMenu, ...onTooltip}"
            class="mx-1"
            height="48px"
            width="48px"
            elevation=0
            large icon tile
          >
            <v-icon
              class="mr-0"
              size="28px"
              :color="notificationColor"
            >
              mdi-bell
            </v-icon>
          </v-btn>
        </template>
        <span>{{$t('menus.topBars.notifications')}}</span>
      </v-tooltip>
    </template>

    <v-card style="min-height: 100%;">
      <v-card-text class="scrollable">
        <NotificationsList>
        </NotificationsList>
      </v-card-text>
    </v-card>

  </v-menu>
</template>

<script>
import NotificationsList from './NotificationsList.vue'

export default {
  name: "NotificationsMenu",
  components: {
    NotificationsList,
  },
  data() {
    return {
      menu: false,
    };
  },
  watch: {
    menu: function(newValue) {
      // this.toursResetted = false;
      if (newValue) {
        this.refreshNotifications();
      }
    }
  },
  methods: {
    refreshNotifications() {
      const REFRESHNOTIFICATIONS = this.$store.getters.keywords.NOTIFICATION.REFRESHNOTIFICATIONS;
      this.$store.dispatch(REFRESHNOTIFICATIONS);
    },
  },
  computed: {
    newNotification: function() {
      const NEWNOTIFICATION = this.$store.getters.keywords.NOTIFICATION.NEWNOTIFICATION;
      return this.$store.getters[NEWNOTIFICATION];
    },
    notificationColor: function() {
      if (this.newNotification == 2) {
        return "error ";
      } else if (this.newNotification) {
        return "primary !important";
      } else {
        return "default";
      }
    },
  }
}
</script>

<style>
.notifications-menu {
  overflow-y: auto;
  width: 500px;
  top: 80px !important;
  bottom: 0px;
  border-radius: 8px;
}

@media only screen and (max-width: 600px) {
  .notifications-menu {
    min-width: 100% !important;
    top: 60px !important;
    left: 0px !important;
    right: 0px !important;
  }
}
</style>