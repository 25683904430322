import { API } from 'aws-amplify';

export default {
  downloadThings: function(payload) {

    let nodeids = payload.nodeids || [];
    let podids = payload.podids || [];
    let assetids = payload.assetids || [];
    let queryParameters = [];

    for (let i = 0; i < nodeids.length; i++) {
      queryParameters.push(encodeURIComponent('nodeids[]') + "=" + encodeURIComponent(nodeids[i]));
    }

    for (let i = 0; i < podids.length; i++) {
      queryParameters.push(encodeURIComponent('podids[]') + "=" + encodeURIComponent(podids[i]));
    }

    for (let i = 0; i < assetids.length; i++) {
      queryParameters.push(encodeURIComponent('assetids[]') + "=" + encodeURIComponent(assetids[i]));
    }

    queryParameters.push(encodeURIComponent('excludeAssets') + "=" + encodeURIComponent(payload.excludeAssets));
    queryParameters.push(encodeURIComponent('id-token') + "=" + encodeURIComponent(payload['id-token']));

    let queryStringParameters = queryParameters.join("&");
    console.log('queryStringParameters', queryStringParameters);

    return API.endpoint('app').then(function(endpoint) {
      console.log("Endpoint:", endpoint);

      let url = endpoint + "download?" + queryStringParameters;
      console.log("url:", url);

      window.location = url;
    });
  },

  sendSupportTicket: function(payload) {
    const options = {
      body: payload,
    };
    return API.post('app', 'support/send', options);
  },

  sendFeatureRequest: function(payload) {
    const options = {
      body: payload,
    };
    return API.post('app', 'support/feature/send', options);
  },

  getLanguages: function() {
    const options = {};
    return API.get('app', 'languages', options);
  },

  createFreeTrial: function(payload) {
    const options = {
      body: payload,
    };
    return API.post('app', 'accountcreate', options);
  },

  salesmanCreatesTenant: function(payload) {
    const options = {
      body: payload,
    };

    return API.post('app', 'salesmancreatestenant', options);
  }

}
