import { API } from 'aws-amplify';

export default {
  // get transcript subtitle lines
  getTranscriptLines: function(podID) {
    const options = {
        // headers, body parameters, query parameters
    };

    return API.get('app', 'pods/'+podID+'/transcripts', options);
  },

  // Create subtitle file from the transcript
  generateFile: function(podID, transcriptID, body) {
    const options = {
      body: body,
    };

    return API.post('app', 'pods/'+podID+'/transcripts/' + transcriptID + '/file', options);
  },

  createTranscript: function(podID, payload) {
    const options = {
      body: payload,
    };
    return API.post('app', 'pods/' + podID + '/transcripts', options);
  },

  updateTranscript: function(podID, transcriptID, payload) {
    const options = {
      body: payload,
    };
    return API.patch('app', 'pods/' + podID + '/transcripts/' + transcriptID, options);
  },

  deleteTranscript: function(podID, transcriptID) {
    const options = {};
    return API.del('app', 'pods/' + podID + '/transcripts/' + transcriptID, options);
  },

  // Add new subtitle line to the transcript
  createTranscriptLine: function(podID, transcriptID, payload) {
    const options = {
        // headers, body parameters, query parameters
        body: payload
    };

    return API.post('app', 'pods/' + podID + '/transcripts/' + transcriptID + '/sentences', options);
  },

  // update subtitle line from the transcript
  updateTranscriptLine: function(podID, transcriptID, payload) {
    const options = {
      body: payload,
    };

    return API.patch('app', 'pods/' + podID +  '/transcripts/' + transcriptID + '/sentences/' + payload.id, options);
  },

  // delete subtitle line from the transcript
  deleteTranscriptLine: function(podID, transcriptID, subtitleID) {
    const options = {};
    return API.del('app', 'pods/' + podID + '/transcripts/' + transcriptID + '/sentences/' + subtitleID, options);
  }
};
