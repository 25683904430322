<template>
  <div id="top-bar-component">
    <v-system-bar window class="first-top-bar px-xs-2  px-sm-5 px-md-10">
      <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          @click="toggleSidemenu()"
          class="mx-1"
          height="48px"
          width="48px"
          elevation=0
          large icon tile
        >
          <v-icon class="mr-0" size="28px">mdi-view-split-horizontal</v-icon>
        </v-btn>

        <router-link v-bind:to="getHeaderLink" class="pod-main-logo mx-1">
            <img class="" src="../../../../assets/images/smallbee.svg" />
        </router-link>


        <v-spacer></v-spacer>


        <!-- <v-btn v-if="$vuetify.breakpoint.smAndUp" tile icon large class="mr-2">
          <v-icon style="margin: 0; padding: 0;">mdi-view-carousel</v-icon>
        </v-btn> -->

        <div v-if="pod" class="top-pod-name mr-2 text-truncate">
          {{pod.name}}
        </div>

        <v-menu
          v-if="getPodVersions.length > 1"
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :small="$vuetify.breakpoint.smAndDown"
              color="primary"
              rounded
              elevation=0
              min-width="20"
            >
              <span>V.</span>
              <v-icon class="mr-0">mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in getPodVersions"
              :key="index"
              :to="'/pod/'+item.id"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>

        <div v-if="showingUpgradeButton && $vuetify.breakpoint.mdAndUp" class="mr-2">
          {{ $tc('layouts.main.subscriptionDaysLeft', getSubscriptionDaysLeft) }}
        </div>

        <v-btn
          v-if="showingUpgradeButton && $vuetify.breakpoint.mdAndUp"
          color="primary"
          elevation=0
          to="/console/subscriptions"
          class="mr-2"
        >
          <v-icon>mdi-crown-circle</v-icon>
          {{$t('layouts.main.upgradeSubscription')}}
        </v-btn>

        <NotificationsMenu>
        </NotificationsMenu>


        <BeeUserMenu :forceManagementConsole="true">
        </BeeUserMenu>

    </v-system-bar>
  </div>
</template>

<script>
import BeeUserMenu from './BeeUserMenu.vue';
import NotificationsMenu from './notifications/NotificationsMenu.vue'

export default {
  name: "PodsTopBar",
  components: {
    BeeUserMenu,
    NotificationsMenu,
  },
  methods: {
    toggleSidemenu() {
      const TOGGLESIDEMENU = this.$store.getters.keywords.MAIN.TOGGLESIDEMENU;
      this.$store.commit(TOGGLESIDEMENU);
    },
  },
  computed: {
    // accessPoints: function() {
    //   const ACCESSPOINTS = this.$store.getters.keywords.NODE.ACCESSPOINTS;
    //   return this.$store.getters[ACCESSPOINTS];
    // },
    getRootNodeId() {
      const ROOTNODEID = this.$store.getters.keywords.AUTH.ROOTNODEID;
      return this.$store.getters[ROOTNODEID];
    },
    getHeaderLink: function() {
      if (this.getRootNodeId) {
        return '/node/' + this.getRootNodeId;
      }

      return '/';
    },
    getDarkMode() {
      const GETDARKMODE = this.$store.getters.keywords.MAIN.GETDARKMODE;
      return this.$store.getters[GETDARKMODE];
    },
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    getPodVersions: function() {
      if (!this.pod) {
        return [];
      }

      const GETPODVERSIONS = this.$store.getters.keywords.POD.GETPODVERSIONS;
      return this.$store.getters[GETPODVERSIONS](this.pod.id);
    },
    showingUpgradeButton() {
      const GETACTIVETENANT = this.$store.getters.keywords.AUTH.GETACTIVETENANT;
      const activeTenant = this.$store.getters[GETACTIVETENANT];

      return  this.$canControlTenantConfig() &&
          !this.$isFCPExtension() &&
          !this.$isAdobeExtension() &&
          activeTenant &&
          activeTenant.subscription &&
          activeTenant.subscription.subscriptionType &&
          activeTenant.subscription.subscriptionType === 'freetrial';
    },
    getSubscriptionDaysLeft() {
      const GETACTIVETENANT = this.$store.getters.keywords.AUTH.GETACTIVETENANT;
      const activeTenant = this.$store.getters[GETACTIVETENANT];

      if (activeTenant?.subscription?.subscriptionEnd) {
        return activeTenant?.subscription?.subscriptionEnd?.days || 0;
      }

      return 0;
    }
  }
}
</script>

<style>

.pod-main-logo {}
.pod-main-logo > img {
  width: 40px;
  height: 33px;
}

@media only screen and (max-width: 960px) {
  .pod-main-logo > img {
    width: 29px;
    height: 24px;
    margin-top: 3px;
  }
}

@media only screen and (max-width: 600px) {
  .pod-main-logo > img {
    width: 24px;
    height: 20px;
    margin-top: 6px;
  }
}

.theme--dark .top-pod-name {
  color: #FFFFFF;
}

.theme--light .top-pod-name {
  color: #000000;
}
</style>
