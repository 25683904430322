import { API } from 'aws-amplify';

export default {
  getUsersReport: function() {
    const options = {};
    return API.get('app', 'reports/users', options);
  },
  getServicesReport: function() {
    const options = {};
    return API.get('app', 'reports/services', options);
  },
  getTenantsReport: function() {
    const options = {};
    return API.get('app', 'reports/tenants', options);
  },
}
