import { API } from 'aws-amplify';

export default {
  getAllTenants: function() {
    const options = {};
    return API.get('app', 'tenants', options);
  },
  deleteTenant: function(id) {
    const options = {};
    return API.del('app', 'tenants/' + id, options);
  },
  restoreTenant: function(payload) {
    const options = {};
    return API.patch('app', `tenants/${payload.nodeId}/restore`, options);
  },
}
