import { API } from 'aws-amplify';

export default {
  getAllPods: function() {
    const options = {
        // headers, body parameters, query parameters
    };

    return API.get('app', 'pods', options);
  },

  getPodByID: function(podID) {
    const options = {};
    return API.get('app', 'pods/'+podID, options);
  },

  getPodMeta: function(podID) {
    const options = {};
    return API.get('app', 'pods/' + podID + "/meta", options);
  },

  getPodActions: function(podID) {
    const options = {};
    return API.get('app', 'pods/' + podID + '/actionlogs', options);
  },

  getPodsByNodeID: function(nodeID) {
    const options = {};
    return API.get('app', 'nodes/' + nodeID + '/pods', options);
  },

  createPod: function(data) {
    const options = {
        // headers, body parameters, query parameters
        body: data,
    };

    return API.post('app', 'pods', options);
  },

  updatePod: function(data) {
    const options = {
      body: data,
    };

    return API.patch('app', 'pods/' + data.id, options);
  },
  updatePodNote: function(data) {
    const options = {
      body: {
        note: data.note,
      },
    };

    return API.patch('app', 'pods/' + data.podId + '/note', options);
  },

  transcodeFile: function(data) {
    const options = {

    };

    return API.post('app', 'pods/' + data.id +"/transcode", options);
  },

  extractArchive: function(data) {
    const options = {
      body: {
        shouldDeletePod: data.shouldDeletePod,
      }
    };

    return API.post('app', 'pods/' + data.id + '/extract', options);
  },

  generateAudio: function(data) {
    const options = {

    };

    return API.post('app', 'pods/' + data.id +"/audio", options);
  },

  deletePod: function(id) {
    const options = {};
    return API.del('app', 'pods/' + id, options);
  },

  createPodAsset: function(data) {
    const options = {
      body: data
    };
    return API.post('app', 'pods/' + data.podid + '/asset', options);
  },

  updatePodAsset: function(data) {
    const options = {
      body: data.payload,
    };

    return API.patch('app', 'pods/' + data.podID + '/assets/' + data.assetID, options);
  },

  deletePodAsset: function(podID, assetID) {
    const options = {
      // queryStringParameters: {
      //   assetid: assetID,
      // }
    };

    return API.del('app', 'pods/' + podID + '/assets/' + assetID, options);
  },

  setAssetLanguage: function(podID, assetID, languageID) {
    const options = {
      body: {
        language: languageID,
      }
    };

    return API.patch('app', `/pods/${podID}/assets/${assetID}/languages`, options);
  },

  createSubtitleAsset: function(data) {
    const options = {
      body: data.payload,
    };

    return API.post('app', 'pods/' + data.podID + '/subtitles', options);
  },

  updateSubtitleAsset: async function(data) {
    const options = {
      body: data.payload,
    };

    return API.patch('app', 'pods/' + data.podID + '/subtitles/' + data.assetID, options);
  },

  promoteAssetToPod: function(data) {
    const options = {
      body: data.payload,
    };

    return API.post('app', '/pods/' + data.podID + '/assets/' + data.assetID + '/promote', options);
  },

  moveManyPods: function(data) {
    const options = {
      body: data,
    };
    return API.patch('app', 'pods/move', options);
  },

  copyManyPods: function(data) {
    const options = {
      body: data,
    };
    return API.post('app', 'pods/copy', options);
  },
  convertSubtitle: function(data) {
    const options = {
      body: data.payload,
    };

    return API.post('app', '/pods/' + data.podID + '/assets/' + data.assetID + '/convert', options);
  },

  addVersion: function(payload) {
    const options = {
      body: {
        versionText: payload.versionText,
      }
    };
    return API.patch('app', `pods/${payload.sourcePodId}/versions/${payload.targetPodId}`, options);
  },
  reorderVersions: function(payload) {
    const options = {
      body: {
        podids: payload.podids,
      }
    }
    return API.post('app', `pods/${payload.podid}/reorder`, options);
  },
  updateTeamOnly: function(data) {
    const options = {
      body: {
        teamonly: data.teamonly,
      },
    };

    return API.patch('app', 'pods/' + data.id + '/teamonly', options);
  },
};
