<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" data-cy="podMenu-tools-imprintSubtitles-activator">
        <slot></slot>
      </div>
    </template>

    <v-form ref="form" v-on:submit.prevent="formSubmit" data-cy="podMenu-tools-imprintSubtitles-form">
      <v-card >
        <v-card-title class="primary d-flex justify-space-between">
          <span>{{$t('routes.pod.menuImprintSubtitles.imprintSubtitles')}}</span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="scrollable mt-4">
          <div class="info mb-4" data-cy="podMenu-tools-imprintSubtitles-selectFile">
            {{asset.name}}
          </div>

          <div data-cy="podMenu-tools-imprintSubtitles-selectFontFamily">
            <v-select
              v-model="fontFamily"
              v-bind:rules="fontFamilyRules"
              v-bind:items="fontFamilies"
              v-bind:label="$t('routes.pod.menuImprintSubtitles.selectFontFamily')"
              v-on:change="fontFamilySelected()"
              solo flat
            ></v-select>
          </div>

          <div data-cy="podMenu-tools-imprintSubtitles-selectFont">
            <v-select
              v-model="font"
              v-bind:rules="fontRules"
              v-bind:items="fonts"
              v-bind:label="$t('routes.pod.menuImprintSubtitles.selectFont')"
              v-bind:disabled="!fontFamily"
              solo flat
            ></v-select>
          </div>

          <!-- This v-if fixes the form reset issue -->
          <div v-if="dialog">
            <v-menu
              v-model="fontColorMenu"
              v-bind:close-on-content-click="false"
              v-bind:nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fontColor"
                  v-bind:label="$t('routes.pod.menuImprintSubtitles.selectFontColor')"
                  v-bind:rules="fontColorRules"
                  append-icon="mdi-format-color-fill"
                  readonly required block
                  solo flat
                  v-bind="attrs"
                  v-on="on"
                  data-cy="podMenu-tools-imprintSubtitles-fontColour"
                ></v-text-field>
              </template>

              <v-color-picker
                v-model="fontColor"
                class="ma-2"
                dot-size="10"
              ></v-color-picker>

            </v-menu>
          </div>


          <!-- This v-if fixes the form reset issue -->
          <div v-if="dialog">
            <v-menu
              v-model="backgroundColorMenu"
              v-bind:close-on-content-click="false"
              v-bind:nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="backgroundColor"
                  v-bind:label="$t('routes.pod.menuImprintSubtitles.selectBackgroundColor')"
                  v-bind:rules="backgroundColorRules"
                  append-icon="mdi-format-color-fill"
                  readonly required block
                  solo flat
                  v-bind="attrs"
                  v-on="on"
                  data-cy="podMenu-tools-imprintSubtitles-backgroundColour"
                ></v-text-field>
              </template>

              <v-color-picker
                v-model="backgroundColor"
                class="ma-2"
                dot-size="10"
                mode="rgba"
              ></v-color-picker>

            </v-menu>
          </div>


          <div v-show="successMessage" class="success" data-cy="podMenu-tools-imprintSubtitles-successMessage">{{successMessage}}</div>
          <div v-if="errorMessage" class="error" data-cy="podMenu-tools-imprintSubtitles-errorMessage">{{errorMessage}}</div>
          <UtilityPodsErrors></UtilityPodsErrors>

        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn type="submit" color="primary" v-bind:disabled="isRunJobsDisabled" elevation=0 data-cy="podMenu-tools-imprintSubtitles-imprintVideo-btn">
            {{$t('routes.pod.menuImprintSubtitles.imprintVideo')}}
          </v-btn>


          <v-btn text @click="dialog = false">{{$t('common.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-dialog>
</template>

<script>
export default {
  name: "ImprintSubtitleDialog",
  props: {
    asset: Object,
  },
  data() {
    return {
      dialog: false,

      successMessage: "",
      errorMessage: "",

      fontFamily: '',
      fontFamilyRules: [
        v => !!v || this.$t('routes.pod.menuImprintSubtitles.fontFamilyRequired'),
      ],

      font: '',
      fontRules: [
        v => !!v || this.$t('routes.pod.menuImprintSubtitles.fontRequired'),
      ],

      fontColorMenu: false,
      fontColor: '',
      fontColorRules: [
        v => !!v || this.$t('routes.pod.menuImprintSubtitles.fontColorRequired'),
      ],

      backgroundColorMenu: false,
      backgroundColor: '',
      backgroundColorRules: [
        v => !!v || this.$t('routes.pod.menuImprintSubtitles.backgroundColorRequired'),
      ],
      preventDoubleclick: false,
      shouldActivateImprintButton: false,

    };
  },
  mounted: function() {

  },
  watch: {
    dialog() {
      this.resetForm();
    },
    isReadyForRunnableCheck: function(newValue) {
      if (newValue) {
        this.checkIsRunnable();
      }
    }
  },
  methods: {
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.$nextTick(() => {
        this.successMessage = "";
        this.errorMessage = "";
        this.fontFamily = '';
        this.font = '';
        this.fontColorMenu = false;
        this.fontColor = '';
        this.backgroundColorMenu = false;
        this.backgroundColor = '';
        this.preventDoubleclick = false;
        this.shouldActivateImprintButton = false;
      });
    },
    fontFamilySelected: function() {
      this.font = "";
    },
    checkIsRunnable: function() {
      this.errorMessage = "";
      this.successMessage = "";

      if (!this.getImprintSubtitlesService) {
        this.errorMessage = "Imprint subtitles service not found!";
        return;
      }

      let utilityPayload = {
        serviceAlias: this.getImprintSubtitlesService.alias,
        pods: [this.pod],
        assetID: this.asset.id,
      }

      let self = this;

      const UPDATEPODSUTILITY = this.$store.getters.keywords.SERVICE.UPDATEPODSUTILITY;
      this.$store.dispatch(UPDATEPODSUTILITY, utilityPayload)
      .then(function() {
        const GETRUNNABLEPODS = self.$store.getters.keywords.SERVICE.GETRUNNABLEPODS;
        const runnable = self.$store.getters[GETRUNNABLEPODS];
        if (runnable.length != 0) {
          self.shouldActivateImprintButton = true;
        } else {
          self.shouldActivateImprintButton = false;
          // throw { response: { data: { error: { message: self.$t("routes.pod.menuImprintSubtitles.operationError")}}}}
        }
      }).
      catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          self.errorMessage = error.response.data.error.message;
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    },
    formSubmit: function() {
      this.errorMessage = "";
      this.successMessage = "";

      const validForm = this.$refs.form.validate();
      if (!validForm) {
        return;
      }

      let self = this;

      this.preventDoubleclick = true;
      setTimeout(() => {
        self.preventDoubleclick = false;
      }, 10000);

      const GETRUNNABLEPODS = self.$store.getters.keywords.SERVICE.GETRUNNABLEPODS;
      const runnable = self.$store.getters[GETRUNNABLEPODS];

      if (runnable.length == 0) {
        throw { response: { data: { error: { message: self.$t("routes.pod.menuImprintSubtitles.operationError")}}}}
      }

      // reverse the RRGGBBAA to AABBGGRR and invert the alpha
      let fontColor = this.fontColor.replace('#', '').match(/../gm);
      let alphaFC = (0xff - parseInt('0x' + fontColor[3])).toString(16).padStart(2, '0');
      fontColor[3] = alphaFC;
      fontColor = fontColor.reverse().join("");

      // reverse the RRGGBBAA to AABBGGRR and invert the alpha
      let backgroundColor = this.backgroundColor.replace('#', '').match(/../gm);
      let alphaBC = (0xff - parseInt('0x' + backgroundColor[3])).toString(16).padStart(2, '0');
      backgroundColor[3] = alphaBC;
      backgroundColor = backgroundColor.reverse().join("");

      let payload = {
        serviceAlias: self.getImprintSubtitlesService.alias,
        utilityPods: runnable,
        params: {
          assetid: self.asset.id,
          font: self.fontFamily + " " + self.font,
          fontColor: fontColor,
          backgroundColor: backgroundColor
        }
      }

      const RUNSERVICE = self.$store.getters.keywords.SERVICE.RUNSERVICE;
      return self.$store.dispatch(RUNSERVICE, payload)
      .then(function() {
        if (self.utilityPodsErrors.length == 0) {
          self.successMessage = self.$t("routes.pod.menuImprintSubtitles.operationQueued");
        }
      })
      .catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          self.errorMessage = error.response.data.error.message;
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }

        self.preventDoubleclick = false;
      });
    }
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    getImprintSubtitlesService: function() {
      const GETIMPRINTSUBTITLES = this.$store.getters.keywords.SERVICE.GETIMPRINTSUBTITLES;
      return this.$store.getters[GETIMPRINTSUBTITLES];
    },
    utilityPodsErrors: function() {
      const GETUTILITYPODSERRORS = this.$store.getters.keywords.SERVICE.GETUTILITYPODSERRORS;
      return this.$store.getters[GETUTILITYPODSERRORS];
    },
    defaultFont: function() {
      return this.fonts[0];
    },
    fontFamilies: function() {
      return [
        'Liberation Mono',
        'Liberation Sans',
        'Liberation Sans Narrow',
        'Liberation Serif',
        'Montserrat',
        'Roboto',
      ];
    },
    fonts: function() {
      let fonts = {
        'Liberation Mono': ['Regular', 'Italic', 'Bold', 'Bold Italic'],
        'Liberation Sans': ['Regular', 'Italic', 'Bold', 'Bold Italic'],
        'Liberation Sans Narrow': ['Regular', 'Italic', 'Bold', 'Bold Italic'],
        'Liberation Serif': ['Regular', 'Italic', 'Bold', 'Bold Italic'],
        'Montserrat': ['Black', 'Black Italic', 'Bold', 'Bold Italic', 'ExtraBold',
                       'ExtraBold Italic', 'ExtraLight', 'ExtraLight Italic', 'Italic',
                       'Light', 'Light Italic', 'Medium', 'Medium Italic', 'Regular',
                       'SemiBold', 'SemiBold Italic', 'Thin', 'Thin Italic'],
        'Roboto': ['Black', 'Black Italic', 'Bold', 'Bold Italic', 'Italic', 'Light',
                    'Light Italic', 'Medium', 'Medium Italic', 'Regular', 'Thin', 'Thin Italic'],
      };
      return fonts[this.fontFamily];
    },
    isRunJobsDisabled: function() {
      if (!this.isReadyForRunnableCheck ||
          !this.shouldActivateImprintButton ||
          this.preventDoubleclick == true
      ) {
        return true;
      }

      return false;
    },
    isReadyForRunnableCheck: function() {
      return this.backgroundColor && this.asset && this.fontFamily && this.font && this.fontColor;
    },
  },

}
</script>

<style>
</style>