import axios from 'axios';

// NOTE: in ./nprogress.js are located some other interceptors

window.regenerateCancelTokenSource = function() {
  window.cancelTokenSource = axios.CancelToken.source();
}

const interceptors = {

  handleContext: function() {
    axios.interceptors.response.use(function(data) {
      if (
          data &&
          data.data &&
          data.data.context &&
          data.data.context.rootnodeid &&
          data.data.context.rootnodeid != window.localStorage.contextnodeid
        ) {
        // store the context node and use it later to evaluate the permissions
        // the context node id is also updated in the AuthStore.js file
        window.localStorage.contextnodeid = data.data.context.rootnodeid;
      }

      return Promise.resolve(data);
    });
  },

  // register interceptor for responses with status 401
  handleUnauthenticated: function(router) {
    axios.interceptors.response.use(undefined, function(error) {

      // if (error && error.response && error.response.status == 404) {
      //   if (!router.currentRoute.path.toLowerCase().startsWith('/404')) {
      //     router.push("/404");
      //   }
      // }
      //
      // if (error && error.response && error.response.status == 403) {
      //   if (!router.currentRoute.path.toLowerCase().startsWith('/forbidden')) {
      //     router.push("/forbidden");
      //   }
      // }

      if (error && error.response && error.response.status == 401 &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.code == 1011) {
        if (!router.currentRoute.path.toLowerCase().startsWith('/account/login')) {
          router.push("/account/login");
        }
      }

      if (error && error.response && error.response.status == 401 &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.code == 1012) {
        if (!router.currentRoute.path.toLowerCase().startsWith('/expired')) {
          router.push("/expired");
        }
      }

      return Promise.reject(error);
    });
  },

  handleForbiddenNotFound: function(store) {

    const CREATESNACKBAR = store.getters.keywords.MAIN.CREATESNACKBAR;

    axios.interceptors.response.use(undefined, function(error) {
      if (error && error.response && error.response.status == 403) {
        const snack = {
          i18n: "common.snackbars.resourceForbidden",
          color: "error",
        };

        store.commit(CREATESNACKBAR, snack);
      }

      if (error && error.response && error.response.status == 404) {
        const snack = {
          i18n: "common.snackbars.resourceNotFound",
          color: "error",
        };

        store.commit(CREATESNACKBAR, snack);
      }

      return Promise.reject(error);
    });
  },

  handleRatelimitExceeded: function(store) {
    const CREATESNACKBAR = store.getters.keywords.MAIN.CREATESNACKBAR;
    axios.interceptors.response.use(undefined, function(error) {

      if (error && error.response && error.response.status == 429) {
        const snack = {
          i18n: "common.snackbars.ratelimitExceeded",
          color: "error",
        };

        store.commit(CREATESNACKBAR, snack);
      }

      return Promise.reject(error);
    });
  },

  handleBandwidthExceeded: function(router) {
    axios.interceptors.response.use(undefined, function(error) {
      if (
          error &&
          error.response &&
          error.response.status &&
          error.response.status == 509 &&
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.status &&
          error.response.data.error.status == 509
        ) {

          if (!router.currentRoute.path.toLowerCase().startsWith('/account/bandwidth-exceeded')) {
            router.push("/account/bandwidth-exceeded");
          }
      }

      return Promise.reject(error);
    });
  },

  makeRequestsCancellable: function() {
    window.regenerateCancelTokenSource();

    axios.interceptors.request.use(function(request) {
      request.cancelToken = window.cancelTokenSource.token;
      return request;
    });
    // axios.interceptors.response.use(function(response) {
    //   console.log("response cancel Token", response);
    //   return response;
    // });
  }

};

export default interceptors;
