import { API } from 'aws-amplify';

export default {
  getComments: function(podID) {
    const options = {};

    return API.get('app', 'pods/'+podID+'/comments', options);
  },

  createComment: function(podID, payload) {
    const options = {
      body: payload,
    };

    return API.post('app', '/pods/' + podID + '/comments', options);
  },

  updateComment: function(podID, commentID, payload) {
    const options = {
      body: payload,
    };

    return API.patch('app', '/pods/' + podID + '/comments/' + commentID, options);
  },

  deleteComment: function(podID, commentID) {
    const options = {};
    return API.del('app', '/pods/' + podID + '/comments/' + commentID, options);
  },

  submitComments: function(podID, payload) {
    const options = {
      body: {
        note: payload.note,
      },
    };
    return API.post('app', '/pods/' + podID + '/comments/submit', options);
  },

  likeComment: function(podID, commentId) {
    const options = {};
    return API.post('app', `/pods/${podID}/comments/${commentId}/like`, options);
  },

  dislikeComment: function(podID, commentId) {
    const options = {};
    return API.post('app', `/pods/${podID}/comments/${commentId}/dislike`, options);
  },

  checkComment: function(podID, commentId) {
    const options = {};
    return API.post('app', `/pods/${podID}/comments/${commentId}/check`, options);
  },

  uncheckComment: function(podID, commentId) {
    const options = {};
    return API.post('app', `/pods/${podID}/comments/${commentId}/uncheck`, options);
  },
}
