import Vue from 'vue'
import VuePlyr from 'vue-plyr'
import VueTour from 'vue-tour'
import VueGtag from 'vue-gtag';
import VueRouter from 'vue-router'
import { Amplify } from 'aws-amplify';
import AudioVisual from 'vue-audio-visual'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'

// CASL library for the permissions
import { Ability } from '@casl/ability';
import { abilitiesPlugin } from '@casl/vue';


import './components/fonts/fonts.css';
import './components/vuetifyOverride.css';

// import 'vue-plyr/dist/vue-plyr.css' // used for version 7 and above...
import './components/DateFilters'   // register the custom data filters
import './components/TimeFilters'   // register the custom data filters
import './components/OtherFilters'   // register the custom data filters
import './components/nprogress';
import './components/globals/registerGlobals';    // register global Vue components
// import awsconfig from './aws-exports-custom';
require('vue-tour/dist/vue-tour.css')

import interceptors from "./components/interceptors"
import permissions from "./components/permissions"
import replaceT from "./components/replaceT"
import showMessage from "./components/showMessage"
import localizeMessage from "./components/localizeMessage"
import vuetify from "./components/vuetify";
import i18n from "./components/i18n";
import router from "./routes/router";
import store from "./store/store";
import App from "./App";


import './components/common.css';

interceptors.handleContext();
interceptors.handleUnauthenticated(router);
interceptors.handleForbiddenNotFound(store);
interceptors.handleBandwidthExceeded(router);
interceptors.handleRatelimitExceeded(store);
interceptors.makeRequestsCancellable();

// assigned in /public/environment.js
Amplify.configure(window.awsconfig);
Vue.use(VueRouter);
Vue.use(vueFilterPrettyBytes);
Vue.use(VuePlyr);
Vue.use(VueTour);
Vue.use(AudioVisual);
Vue.use(replaceT);
Vue.use(localizeMessage);
Vue.use(showMessage);
Vue.use(permissions);
Vue.use(VueReCaptcha, { siteKey: window.awsconfig.recaptcha_site_key});

Vue.use(abilitiesPlugin, new Ability, {
  useGlobalProperties: true
});

Vue.prototype.$Amplify = Amplify;
Vue.config.productionTip = false;

// disable the tracking for dev and test environments
const GAEnabled = !window.awsconfig.env.startsWith("dev") && !window.awsconfig.env.startsWith("test");

Vue.use(VueGtag, {
  config: { id: "G-D62M7DQW03" },
  enabled: GAEnabled,
}, router);


new Vue({
  render: (h) => h(App),
  vuetify,
  router,
  i18n,
  store,
}).$mount("#app");


// Vuejs 2 error tracking handler
Vue.config.errorHandler = function (err, vm, info) {
  vm; info;
  let exception = err.message + ' ||| ' +err.stack;

  Vue.$gtag.exception({
    description: exception,
    fatal: false
  });

  throw err;
}

// window error tracking of unhandled promises
window.addEventListener('unhandledrejection', function(event) {
  let exception = event.type + ' ||| ' + event.reason;
  Vue.$gtag.exception({
    description: exception,
    fatal: false
  });
});
