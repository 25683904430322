<template>
  <v-form ref="form" v-bind:disabled="isFormDisabled" class="" >
    <div class="labels-edit-form-header mt-2 d-flex justify-space-between" style="">
      <div v-if="this.isVideoOrAudioPod" class="d-flex">
        <div class="labels-edit-form-label" style="">
          {{$t('routes.pod.menuEditLabels.inTime')}}
        </div>
        <div style="width: 22%;">
          <v-text-field
            v-bind:disabled="!this.isVideoOrAudioPod || this.storeAsMeta == true"
            v-bind:label="$t('routes.pod.menuEditLabels.inTime')"
            v-on:focus="updateFocusedInput('in')"
            v-model="inTime"
            v-bind:rules="timeInputRules"
            hide-details="auto"
            block dense
            solo flat
            data-cy="podMenu-labels-inTime"
            >
          </v-text-field>
        </div>

        <div class="labels-edit-form-label" style="">
          {{$t('routes.pod.menuEditLabels.outTime')}}
        </div>
        <div style="width: 22%;">
          <v-text-field
            v-bind:disabled="!this.isVideoOrAudioPod || this.storeAsMeta == true"
            v-bind:label="$t('routes.pod.menuEditLabels.outTime')"
            v-on:focus="updateFocusedInput('out')"
            v-model="outTime"
            v-bind:rules="timeInputRules.concat(outTimeComparison)"
            hide-details="auto"
            block dense
            solo flat
            data-cy="podMenu-labels-outTime">
            >
          </v-text-field>
        </div>

      </div>

      <div class="ml-auto">
        <!-- <div class="text-center" style="margin-top: -12px;" data-cy="podMenu-labels-duration">
          <label style="font-size:0.75rem;">{{$t('routes.pod.menuEditLabels.duration')}}</label><br/>
           {{calculatedDuration}} {{$t('routes.pod.menuEditLabels.secondsShort')}}
        </div> -->

        <v-tooltip bottom z-index="1200">
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile icon v-bind="attrs" v-on="on" v-on:click.prevent="closeForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>

          <span>{{$t('common.close')}}</span>
        </v-tooltip>
      </div>
    </div>

    <div class="labels-edit-form-body ">
      <div class="mb-2">
        <v-textarea
          v-model="labelLine"
          class="media-list-notes-textarea"
          v-bind:rules="labelLineRules"
          v-bind:error-messages="labelLineError"
          v-bind:placeholder="$t('routes.pod.menuEditLabels.addLabel')"
          hide-details="auto"
          rows="3"
          required
          solo flat
          ref="labelTextArea"
          data-cy="podMenu-labels-addLabelLine"
        >
        </v-textarea>
      </div>

      <div class="d-flex justify-space-between" data-cy="podMenu-labels-options">

          <div class="d-flex" data-cy="podMenu-labels-checkboxMeta">
            <v-checkbox
              v-if="!isMlLabel"
              v-model="storeAsMeta"
              dense
              v-bind:label="$t('routes.pod.menuEditLabels.storeAsMetaLabel')"
              v-bind:disabled="!this.isVideoOrAudioPod"
              hide-details="auto"
            >
            </v-checkbox>
          </div>

        <div v-if="$canControlPodService()" class="text-right">
          <v-tooltip bottom z-index="1200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn tile icon v-bind="attrs" v-on="on" v-show="editItem" v-on:click.prevent="deleteLabel()" data-cy="podMenu-labels-delete-btn">
                <v-icon small color="primary">mdi-delete-outline</v-icon>
              </v-btn>
            </template>

            <span>{{$t('common.delete')}}</span>
          </v-tooltip>

          <v-tooltip bottom z-index="1200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn tile icon v-bind="attrs" v-on="on" v-on:click.prevent="closeForm()" data-cy="podMenu-labels-cancel-btn">
                <v-icon small color="primary">mdi-cancel</v-icon>
              </v-btn>
            </template>

            <span>{{$t('common.close')}}</span>
          </v-tooltip>

          <v-tooltip bottom z-index="1200">
            <template v-slot:activator="{ on, attrs }">
              <v-btn tile icon v-bind="attrs" v-on="on" v-on:click.prevent="saveLabel()" data-cy="podMenu-labels-save-btn">
                <v-icon small color="primary">mdi-floppy</v-icon>
              </v-btn>
            </template>

            <span>{{$t('common.save')}}</span>
          </v-tooltip>

        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue';

export default {
  name: "PodEditLabelForm",
  props: {
    pod: {
      type: Object,
      required: true,
    },
    editItem: {
      type: Object,
      required: false,
    },
    isMlLabel: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    let self = this;
    return {
      focusedInput: 'in',
      storeAsMeta: false,

      labelLine: "",
      labelLineError: "",
      labelLineRules: [
        v => !!v || this.$t('routes.pod.menuEditLabels.labelIsRequired'),
      ],

      inTime: "00:00.00",
      outTime: "00:01.00",
      timeInputRules: [
        v => !!v || this.$t('routes.pod.menuEditLabels.timeIsRequired'),
        v => /\d{2,}:\d{2}\.\d{2}$/gm.test(v) || this.$t('routes.pod.menuEditLabels.invalidFormat'),
        function(v) {
            if (!self.isVideoOrAudioPod) return true;
            let seconds = (v || "").split(".")[0].split(":")[1];
            if (parseInt(seconds) >= 60) return self.$t('routes.pod.menuEditLabels.invalidSeconds');
            return true;
        },
        function(v) {
            if (!self.isVideoOrAudioPod) return true;
            let videoDuration = (self.transcodedAsset && self.transcodedAsset.duration) ? parseFloat(self.transcodedAsset.duration) : 0;
            if (!videoDuration) return true;
            if ((self.videotimeToInt(v) / 1000) > videoDuration) return self.$t('routes.pod.menuEditLabels.incorrectTime');
            return true;
        }
      ],
    };
  },
  mounted: function() {
    if (!this.isVideoOrAudioPod) {
      this.storeAsMeta = true;
    }
  },
  methods: {
    updateFocusedInput: function(input) {
      this.focusedInput = input;
    },
    closeForm: function() {
      this.$emit('closeForm', true);
      this.resetForm();
    },
    resetForm: function() {
      // reset form
      this.storeAsMeta = !this.isVideoOrAudioPod;
      this.focusedInput = "";
      this.labelLine = "";
      this.labelLineError = "";

      this.inTime = "00:00.00";
      this.outTime = "00:01.00";

      this.$refs["labelTextArea"].$refs.input.focus();
      this.$refs.form.resetValidation();
    },
    deleteLabel: function() {
      if (!this.editItem) {
        return;
      }

      const payload = {
        podID: this.pod.id,
        labelID: this.editItem.id,
      };

      let self = this;
      let VERB = this.$store.getters.keywords.POD.LABEL.DELETELABEL;

      if (this.isMlLabel && this.editItem) {
        VERB = this.$store.getters.keywords.POD.LABEL.DELETEMLLABEL;
      }

      this.$store.dispatch(VERB, payload)
      .then(function() {
        self.closeForm();
      }).catch(function(error) {
        console.log("Error updating/ saving label");
        if (error && error.response && error.response.data && error.response.data.error) {
          self.labelLineError = error.response.data.error.message;
        } else {
          self.labelLineError = "Error!";
          console.log(error);   // unhandled error
        }
      });
    },
    saveLabel: function() {
      const validForm = this.$refs.form.validate();
      this.labelLineError = "";
      if (!validForm || !this.pod) {
        return;
      }

      let data = {
        podID: this.pod.id,
        payload: {
          start: this.storeAsMeta ? null : this.videotimeToInt(this.inTime),
          end: this.storeAsMeta ? null : this.videotimeToInt(this.outTime),
          content: this.labelLine,
        }
      };

      if (this.isMlLabel) {
        data.payload.type = this.editItem.type;
      }

      let self = this;
      let VERB = this.$store.getters.keywords.POD.LABEL.CREATELABEL;

      if (this.editItem) {
        data.labelID = this.editItem.id;
        VERB = this.$store.getters.keywords.POD.LABEL.UPDATELABEL;
      }

      if (this.isMlLabel && this.editItem) {
        VERB = this.$store.getters.keywords.POD.LABEL.UPDATEMLLABEL;
      }

      this.$store.dispatch(VERB, data)
      .then(function() {
        self.closeForm();
      })
      .catch(function(error) {
        if (error?.response?.data?.error) {
          self.labelLineError = self.$localizeMessage(error.response.data.error);
        } else {
          self.labelLineError = self.$t('backend.system-error');
          console.log(error?.response?.data); // unhandled error
        }
      });
    },
    outTimeStart: function() {
      if (this.playerCURRENTTIME + 1 <= this.videoDuration) {
        return Vue.filter("toTimeMilliseconds")(this.playerCURRENTTIME + 1);
      }
      return Vue.filter("toTimeMilliseconds")(this.videoDuration);
    },
    videotimeToInt: function(value) {
      let minutes = value.split(":")[0];
      let seconds = value.split(".")[0].split(":")[1];
      let milliseconds = value.split(".")[1];

      milliseconds = parseInt(parseFloat("0." + milliseconds) * 1000);
      seconds = parseInt(seconds) * 1000;
      minutes = parseInt(minutes) * 60000;
      return minutes + seconds + milliseconds;
    },
  },
  watch: {
    editItem: function(newItem) {
      this.$refs["labelTextArea"].$refs.input.focus();
      this.$refs.form.resetValidation();

      if (newItem) {
        if (this.isVideoOrAudioPod) {
          this.focusedInput = "in";

          if (this.editItem.start != null) {
            this.storeAsMeta = false;
          } else {
            this.storeAsMeta = true;
          }
        } else {
          this.storeAsMeta = true;
        }

        this.labelLine = newItem.content;
        this.labelLineError = "";
        if (this.editItem.start != null) {
          this.inTime = Vue.filter("toTimeMilliseconds")(newItem.start);
          this.outTime = Vue.filter("toTimeMilliseconds")(newItem.end);
        }
      } else {
        // reset form
        this.resetForm();
      }


    },
    playerCURRENTTIME: function(newTime) {
        // this.inTime = Vue.filter("toTimeMilliseconds")(newTime);
        // if (newTime + 1 <= this.videoDuration) {
        //   this.outTime = Vue.filter("toTimeMilliseconds")(newTime + 1);
        // } else {
        //   this.outTime = Vue.filter("toTimeMilliseconds")(this.videoDuration);
        // }

        if (this.focusedInput == 'in') {
          this.inTime = Vue.filter("toTimeMilliseconds")(newTime);
          if(this.editItem == null) {
            let outTime = Math.min(newTime + 1, this.videoDuration);
            this.outTime = Vue.filter("toTimeMilliseconds")(outTime);
          }
        }

        if (this.focusedInput == 'out') {
          this.outTime = Vue.filter("toTimeMilliseconds")(newTime);
        }
    }
  },
  computed: {
    outTimeComparison: function () {
      if (!this.isVideoOrAudioPod) return true;
      return this.videotimeToInt(this.inTime) <= this.videotimeToInt(this.outTime) ||
        this.$t('routes.pod.menuTranscript.invalidOutTime');
    },
    videoDuration: function() {
      const videoDuration = (this.rawAsset && this.rawAsset.duration) ? parseFloat(this.rawAsset.duration) : 0;
      return videoDuration;
    },
    rawAsset: function() {
      if (this.pod &&
          this.pod.asset &&
          this.pod.asset.raw &&
          this.pod.asset.raw[0]) {
        return this.pod.asset.raw[0];
      }
      return null;
    },
    transcodedAsset: function() {
      if (!this.pod ||
          !this.pod.asset ||
          !this.pod.asset.web ||
          !Array.isArray(this.pod.asset.web)) {
        return null;
      }

      // Get first web asset with status COMPLETE
      for(let i = 0; i < this.pod.asset.web.length; i++) {
        if (this.pod.asset.web[i].state == this.getPodStates.COMPLETE) {
          return this.pod.asset.web[i];
        }
      }

      return null;
    },
    isVideoPod: function() {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
    isAudioPod: function() {
      const ISAUDIOPOD = this.$store.getters.keywords.POD.ISAUDIOPOD;
      return this.$store.getters[ISAUDIOPOD];
    },
    isVideoOrAudioPod: function() {
      return this.isVideoPod || this.isAudioPod;
    },
    calculatedDuration: function() {
      return ((this.videotimeToInt(this.outTime) - this.videotimeToInt(this.inTime))/1000).toFixed(2);
    },
    getPodStates: function() {
      const PODSTATES = this.$store.getters.keywords.COMMON.PODSTATES;
      return this.$store.getters[PODSTATES];
    },
    playerCURRENTTIME: function() {
      const CURRENTTIME = this.$store.getters.keywords.PLAYER.CURRENTTIME;
      return this.$store.getters[CURRENTTIME];
    },
    isFormDisabled: function() {
      return this.isMlLabel && !this.editItem;
    }
  }
}
</script>

<style>
.labels-edit-form-header {
  background-color: #FFC333;
  border-radius: 10px 10px 0px 0px;
  padding: 8px 0px;
}

.labels-edit-form-body {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 0px 0px 10px 10px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
}

.theme--dark .labels-edit-form-body {
  border-color: #EBEBEB;
}
.theme--light .labels-edit-form-body {
  border-color: #EBEBEB;
}



.labels-edit-form-label {
  width: 10%;
  padding-top: 9px;
  padding-right: 4px;
  text-align:right;
  font-weight: 600;
  text-transform: uppercase;
}

.theme--dark .labels-edit-form-label {
  color: #333333;
}

.theme--light .labels-edit-form-label {
  color: #FFFFFF;
}
</style>
