import Api from '../../api/Api'
// import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    uploadlinks: [],
    uploadlinkInfo: null,
  },
  getters: {
    getUploadLinks: function(state) {
      return state.uploadlinks;
    },

    // getting the external uploadlink info
    getUploadlinkInfo: function(state) {
      return state.uploadlinkInfo;
    },
  },
  mutations: {
    setUploadLinks: function(state, links) {
      state.uploadlinks = links;
    },

    // setting the external uploadlink info
    setUploadlinkInfo: function(state, info) {
      state.uploadlinkInfo = info;
    },

    updateUploadlinks: function(state, links) {
      state.uploadlinks = links;
    }
  },
  actions: {
    refreshUploadLinks: function(context) {
      const GETDBUSER = context.rootGetters.keywords.AUTH.GETDBUSER;
      const ROOTNODEID = context.rootGetters.keywords.AUTH.ROOTNODEID;
      const dbUser = context.rootGetters[GETDBUSER];
      const rootNodeId = context.rootGetters[ROOTNODEID];

      if (!dbUser || !dbUser.id || !rootNodeId) {
        return;
      }

      // return Api.uploadlink.getUploadLinks(nodeID)
      return Api.uploadlink.getAllUploadlinks(dbUser.id, rootNodeId)
      .then(function(response) {
        context.commit('setUploadLinks', response.data);
      })
    },

    createUploadLink: function(context, payload) {
      return Api.uploadlink.createUploadLink(payload)
      .then(function(response) {
        return response.data;
      });
    },

    updateUploadLink: function(context, payload) {
      return Api.uploadlink.updateUploadLink(payload.id, payload.formData)
      .then(function(response) {
        return response.data;
      });
    },

    deleteUploadLink: function (context, uploadlinkID) {
      return Api.uploadlink.deleteUploadLink(uploadlinkID)
      .then(function(response) {
        return response.data;
      });
    },

    inviteUploadlink: function (context, payload) {
      return Api.uploadlink.inviteUploadlink(payload)
        .then(function(response) {
          return response.data;
        });
    },

    // External call
    refreshUploadlinkInfo: function(context, code) {
      return Api.uploadlink.getUploadAccessInfo(code)
      .then(function(response) {
        context.commit('setUploadlinkInfo', response.data);
        return response.data;
      })
    },

    // External call
    authorizeUploadlink: function(context, data) {
      return Api.uploadlink.authorizeUploadlink(data)
      .then(function(response) {
        if (response.data.accesstoken) {
          context.commit('setUploadlinkInfo', response.data);
        }

        return response.data;
      })
    },

    checkStorage: function(context, units) {
      const accesstoken = context.getters.getUploadlinkInfo.accesstoken;
      return Api.uploadlink.checkStorage(accesstoken, units);
    },

    createPod: function(context, payload) {
      const accesstoken = context.getters.getUploadlinkInfo.accesstoken;
      return Api.uploadlink.createPod(payload, accesstoken)
      .then(function(response) {
        return response.data;
      });
    },
  }
}
