<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" style="display: inline;" data-cy="commentItem-actions-list-delete-activator">
        <slot></slot>
      </div>
    </template>

    <div class="vb-dialog vb-dialog-danger">
    <v-card data-cy="dialog-deleteComment-modal">
      <v-card-title class="d-flex justify-space-between">
        <span>
          {{$t('routes.pod.menuComment.deleteComment')}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-5">
        {{$t('routes.pod.menuComment.deleteCommentDialogMessage')}}
        <blockquote class="pa-2 font-italic" >
          {{ comment.content}}
        </blockquote>
        <div v-if="errorMessage" class="error  px-1" data-cy="dialog-createSubtitle-errorMessage">
          {{errorMessage}}
        </div>

        <div v-if="errorTranslatable" class="error px-1">
          {{$replaceT(errorTranslatable.code, errorTranslatable.params, errorTranslatable.content)}}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-on:click="deleteComment" class="btnOk" elevation=0 data-cy="dialog-deleteComment-ok">
          {{$t('common.delete')}}
        </v-btn>
        <v-btn text @click="dialog = false" class="btnCancel" data-cy="dialog-deleteComment-false">
          {{$t('common.cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "CommentDeleteDialog",
  props: {
    comment: {
      type: Object,
      required: true,
    }
  },
  data: function() {
    return {
      dialog: false,
      errorMessage: '',
      errorTranslatable: null,
    };
  },
  methods: {
    deleteComment: function() {
      this.errorMessage = "";
      this.errorTranslatable = null;

      let self = this;
      const REFRESHCOMMENTS = this.$store.getters.keywords.POD.COMMENT.REFRESHCOMMENTS;
      const DELETECOMMENT = this.$store.getters.keywords.POD.COMMENT.DELETECOMMENT;

      this.$store.dispatch(DELETECOMMENT, this.comment)
      .then(function() {
        self.$store.dispatch(REFRESHCOMMENTS, self.comment.podid);
        self.dialog = false;
      }).catch(function(error) {
        if (error && error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error.message;
          if (typeof apiError == 'object') {
            self.errorTranslatable = apiError;
          } else {
            self.errorMessage = apiError;
          }
        } else {
          self.errorMessage = "Error!";
          console.log(error);   // unhandled error
        }
      });
    }
  }
}
</script>

<style>

</style>
