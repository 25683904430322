import { API } from 'aws-amplify';

export default {
  getUsersData: function() {
    const options = {};
    return API.get('app', 'logs/users', options);
  },
  getUserUsage: function(userId) {
    const options = {};
    return API.get('app', 'logs/users/' + userId, options);
  },


  getTenantsData: function() {
    const options = {};
    return API.get('app', 'logs/tenants', options);
  },
  getTenantUsage: function(tenantId) {
    const options = {};
    return API.get('app', 'logs/tenants/' + tenantId, options);
  },
}
