class showMessage {
  constructor() {
  }

  install(Vue) {
    Vue.prototype.$showMessage = function(message, options = {}) {
      const snack = {
        message: message,
        color: "success",
      };

      if (options.type === 'error') {
        snack.color = "error";
      }

      const CREATESNACKBAR = this.$store.getters.keywords.MAIN.CREATESNACKBAR;
      this.$store.commit(CREATESNACKBAR, snack, {root: true});
    }
  }
}

export default new showMessage();
