import { API } from 'aws-amplify';

export default {
  updateFirstName: function(payload) {
    const options = {
      body: {
        firstName: payload.firstName,
      },
    };

    return API.patch('app', 'accounts/firstname', options);
  },
  updateLastName: function(payload) {
    const options = {
      body: {
        lastName: payload.lastName,
      },
    };

    return API.patch('app', 'accounts/lastname', options);
  },
  updateNotificationSetting: function(payload) {
    const options = {
      body: {
        name: payload.name,
        value: payload.value,
      },
    };

    return API.patch('app', 'accounts/settings/notification', options);
  },
  updateLanguage: function(payload) {
    const options = {
      body: {
        language: payload.language,
      },
    };

    return API.patch('app', 'accounts/settings/language', options);
  },
  updateEmailAddress: function(payload) {
    const options = {
      body: {
        email: payload.email,
      },
    };

    return API.patch('app', 'accounts/email', options);
  },
  changeTheme: function(payload) {
    const options = {
      body: {
        theme: payload.theme,
      },
    };

    return API.patch('app', 'accounts/settings/theme', options);
  },
  changeFolderView: function(payload) {
    const options = {
      body: {
        view: payload.view,
      },
    };

    return API.patch('app', 'accounts/settings/folderview', options);
  },
  getUsage: function(payload) {
    const options = {
      queryStringParameters: {
        tid: payload.tenantId,
      },
    };

    return API.get('app', 'logs/usage', options);
  },

  getAccountDeletionInfo: function() {
    const options = {};

    return API.get('app', `accounts/deletion`, options);
  },
  deleteAccount: function() {
    const options = {};

    return API.del('app', `accounts/me`, options);
  }
}
