import Api from '../../api/Api'
// import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    accesslinks: [],
    accesslinkInfo: {},
    viewPod: null,
    comments: [],
  },
  getters: {
    getAccesslinks: function(state) {
      return state.accesslinks;
    },
    getAccesslinkInfo: function(state) {
      return state.accesslinkInfo;
    },
    getViewPod: function(state) {
      return state.viewPod;
    },
    getComments: function(state) {
      return state.comments;
    },
  },
  mutations: {
    setAccesslinks: function(state, links) {
      state.accesslinks = links;
    },
    setAccesslinkInfo: function(state, token) {
      state.accesslinkInfo = token;
    },
    setViewPod: function(state, pod) {
      state.viewPod = pod;
    },
    removeAccesslink: function(state, accesslinkId) {
      for (let i = 0; i < state.accesslinks.length; i++) {
        if (state.accesslinks[i].id == accesslinkId) {
          state.accesslinks.splice(i, 1);
          break;
        }
      }
    },
    updateAccesslinks: function(state, accesslinks) {
      state.accesslinks = accesslinks;
    },
    addComment: function(state, comment) {
      if (comment.start !== null) {
        comment.start /= 1000;
      }
      if (comment.end !== null) {
        comment.end /= 1000;
      }

      state.comments.push(comment);
    },
    updateComment: function(state, comment) {
      if (comment.start !== null) {
        comment.start /= 1000;
      }
      if (comment.end !== null) {
        comment.end /= 1000;
      }

      for (let i = 0; i < state.comments.length; i++) {
        if (state.comments[i].id == comment.id) {
          Object.assign(state.comments[i], comment);
          break;
        }
      }
    },
    removeComment: function(state, commentId) {
      const newList = [];
      for (const comment of state.comments) {
        if (comment.id !== commentId) {
          newList.push(comment);
        }
      }

      state.comments = newList;
    },
  },
  actions: {
    refreshAccesslinks: function(context, nodeId) {
      // const GETDBUSER = context.rootGetters.keywords.AUTH.GETDBUSER;
      // const dbUser = context.rootGetters[GETDBUSER];

      // if (!dbUser || !dbUser.id) {
      //   return;
      // }

      // return Api.accesslink.getAccesslinksByPod(podID)
      // return Api.accesslink.getAccesslinksByUser(dbUser.id)
      return Api.accesslink.getAccesslinksByNode(nodeId)
      .then(function(response) {
        context.commit('setAccesslinks', response.data || []);
      })
    },

    createAccesslink: function(context, payload) {
      return Api.accesslink.createAccesslink(payload)
      .then(function(response) {
        return response.data;
      });
    },

    updateAccesslink: function(context, payload) {
      const id = payload.id;
      delete payload.id;
      return Api.accesslink.updateAccesslink(id, payload)
      .then(function(response) {
        return response.data;
      });
    },

    deleteAccesslink: function (context, payload) {
      const { accessLinkId, podId } = payload;
      return Api.accesslink.deleteAccesslink(podId, accessLinkId)
      .then(function(response) {
        context.commit('removeAccesslink', accessLinkId);
        return response.data;
      });
    },

    inviteAccesslink: function (context, payload) {
      return Api.accesslink.inviteAccesslink(payload)
      .then(function(response) {
        return response.data;
      });
    },

    // External call
    getAccesslinkInfo: function(context, code) {
      //context.commit('setAccesslinkInfo', {});
      return Api.accesslink.getAccesslinkInfo(code)
      .then(function(response) {
        context.commit('setAccesslinkInfo', response.data);
        return response.data;
      })
    },
    // External call
    authorizeAccesslink: function(context, data) {
      return Api.accesslink.authorizeAccesslink(data)
      .then(function(response) {
        if (response.data.accesstoken) {
          context.commit('setAccesslinkInfo', response.data);
        }

        return response.data;
      })
    },

    refreshAccesslinkPod: function(context) {
      let data = {
        accesstoken: context.getters.getAccesslinkInfo.accesstoken,
      };

      return Api.accesslink.getAccesslinkPod(data)
      .then(function(response) {
        context.commit('setViewPod', response.data);
      });
    },

    addComment: function(context, payload) {
      // add the access token
      payload.accesstoken = context.getters.getAccesslinkInfo.accesstoken;

      return Api.accesslink.addComment(payload)
      .then(function(response) {
        context.commit('addComment', response.data);
        return response.data;
      });
    },
    updateComment: function(context, payload) {
      // add the access token
      payload.accesstoken = context.getters.getAccesslinkInfo.accesstoken;

      return Api.accesslink.updateComment(payload)
      .then(function(response) {
        context.commit('updateComment', response.data);
        return response.data;
      });
    },
    deleteComment: function(context, payload) {
      // add the access token
      payload.accesstoken = context.getters.getAccesslinkInfo.accesstoken;

      return Api.accesslink.deleteComment(payload)
        .then(function() {
          context.commit('removeComment', payload.id);
        });
    }
  }
}
