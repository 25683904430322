<template>
  <v-app dark id="login-layout">

    <div class="background-image" style="">
    </div>

    <!-- <top-bar v-bind:emptyTopBar="true">
    </top-bar> -->

    <snackbars>
    </snackbars>

    <!-- <div class="content-wrapper">
      <router-view></router-view>
    </div> -->

    <v-main>
      <v-container fluid style="min-height: 100%; display: flex; justify-content: center;">
        <div class="content-wrapper" style="">
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Snackbars from "../components/Snackbars.vue"

export default {
  name: 'LoginLayout',
  components: {
    Snackbars,
  },
  mounted() {
    this.$vuetify.theme.dark = true;
  }
}
</script>

<style>
  #login-layout {
    width: 100%;
    min-height: 100%;
    /* background-image: url("../assets/images/vb-login-background.png");
    background-repeat: no-repeat;
    background-size: cover; */
  }

  #login-layout .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/vb-login-background.webp");
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.1;
  }

  #login-layout .content-wrapper {
    width: 460px;
    display: flex;
    justify-content: center;
    align-self: center;
  }

  #login-layout .content-wrapper > div {
    width: 100%;
  }

  /* #login-layout .content-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 4%;
  } */

  #login-layout .content {
    width: 100%;
    /* width: 460px; */
    /* height: 300px; */
    background-color: #1b2129;
    padding: 30px 40px;
    border-radius: 25px;
    /* box-shadow: #393939 0px 2px 2px;
    border: 1px solid #373737; */
  }

  .login-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 960px) {
    /* #login-layout .content {
      width: 100% !important;
    } */
  }

  @media only screen and (max-width: 600px) {
    #login-layout .content-wrapper {
      width: 100%;
    }
    /* #login-layout .content-wrapper > div {
      width: 100%;
    } */
    /* #login-layout .content {
      width: 100% !important;
    } */
  }


  /* some browsers change the color of the prefilled inputs */
  #login-layout input:-webkit-autofill {
    background-color: rgba(0, 0, 0, 0) !important;
    background-image: none !important;
  }
</style>
