<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-form ref="form" data-cy="podMenu-tools-saveFrame-form">
      <v-card >
        <v-card-title class="primary d-flex justify-space-between">
          <span>{{$t('routes.pod.menuGenerateAudio.missingAudio')}}</span>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="scrollable mt-4 pt-4">

          <div>{{$t('routes.pod.menuGenerateAudio.generateAudioQuestion')}}</div>


        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-on:click.prevent="generateAudio()"
            :disabled="isExtractingAudio || preventDoubleclick"
            elevation="0"
            color="primary"
            data-cy=""
          >
            {{$t('routes.pod.menuGenerateAudio.generateAudio')}}
          </v-btn>

          <v-btn text @click="dialog = false">{{$t('common.close')}}</v-btn>

        </v-card-actions>

      </v-card>
    </v-form>

  </v-dialog>
</template>


<script>
export default {
  name: "GenerateAudioDialog",
  props: {
    pod: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,

      successMessage: "",
      errorMessage: "",
      errorTranslatable: null,

      preventDoubleclick: false,
    };
  },
  methods: {
    generateAudio: function() {
        this.preventDoubleclick = true;
        let self = this;
        setTimeout(() => {
          self.preventDoubleclick = false;
        }, 10000);

        let data = {
            id: this.pod.id,
        };

        const GENERATEAUDIO = this.$store.getters.keywords.POD.GENERATEAUDIO;
        this.$store.dispatch(GENERATEAUDIO, data)
        .catch(function(error) {
            if (error && error.response && error.response.data && error.response.data.error) {
                const apiError = error.response.data.error.message;
                if (typeof apiError == 'object') {
                    self.errorTranslatable = apiError;
                } else {
                    self.errorMessage = apiError;
                }
                console.log(error);
            } else {
              self.errorMessage = "Error!";
            }
            self.preventDoubleclick = false;
        });
    },
  },
  computed: {
    isExtractingAudio: function() {
      if (this.pod && this.pod.launchedServices && this.pod.launchedServices.length > 0) {
        for (let i = 0; i < this.pod.launchedServices.length; i++) {
          if (this.pod.launchedServices[i].code == "AM10") {
            return true;
          }
        }
      }

      return false;
    },
  }
}
</script>

<style>
</style>