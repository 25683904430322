import { API } from 'aws-amplify';

export default {
    getAllServices: function() {
      const options = {
          // headers, body parameters, query parameters
      };

      return API.get('app', 'services', options);
    },

    getServiceOptions: function(serviceAlias) {
      const options = {};
      return API.get('app', 'services/' + serviceAlias + "/options", options);
    },

    getPodsUtility: function(serviceAlias, nodeIDs, podIDs, assetID, sourceLanguage, targetLanguage, action) {
      const options = {
        queryStringParameters: {
          nodes: nodeIDs.length > 0 ? nodeIDs : '',
          pods: podIDs.length > 0 ? podIDs : '',
          assetid: assetID,
          sourcelanguage: sourceLanguage,
          targetlanguage: targetLanguage,
          action: action,
        },
      };

      return API.get('app', 'services/' + serviceAlias, options);
    },

    runService: function(serviceAlias, nodeIDs, podIDs, params) {
      const options = {
        body: {
          nodes: nodeIDs,
          pods: podIDs,
          params: params,
        }
      };

      return API.post('app', 'services/' + serviceAlias, options);
    },



    // get list of the resources - limit and consumed units
    getResources: function(payload) {
      const options = {};
      return API.get('app', `tenants/${payload.accessPointId}/resources`, options);
    },

    checkResource: function(resourceName, units, contextNodeId) {
      const options = {};
      return API.get('app', `tenants/${contextNodeId}/resources/${resourceName}/status?units=${units}`, options);
    }
}
