<template>
  <div class="pod-comment-item mt-2" v-bind:class="getPodCommentClasses">

    <div v-if="!showEditForm" class="px-4 py-2">
      <div class="d-flex justify-space-between align-center">
        <div v-on:click="commentClick" class="" style="max-width: 44%;">
          <v-tooltip bottom open-delay="200">
            <template v-slot:activator="{ on, attrs }">
                <h3 v-bind="attrs" v-on="on" class="text-truncate">{{getCreatedBy}}</h3>
            </template>
            <span>{{getCreatedBy}}</span>
          </v-tooltip>
        </div>

        <div v-if="comment.start !== null" v-on:click="commentClick" style="max-width: 37%;">
          <div v-if="!showCompact" class="pod-comment-inout-time" data-cy="commentItem-inOutTime">
            <div class="d-flex flex-row float-left mr-2">
              <div class="mr-1 text-uppercase">
                {{$t('routes.pod.menuComment.inTime')}}
              </div>
              <div>
                {{comment.start | toTimeMilliseconds}}
              </div>
            </div>
            <div v-if="comment.end" class="d-flex flex-row float-left">
              <div  class="mr-1 text-uppercase">
                {{$t('routes.pod.menuComment.outTime')}}
              </div>
              <div>
                {{comment.end | toTimeMilliseconds}}
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-center" style="max-width: 19%;">
          <v-tooltip bottom open-delay="200" z-index="1200">
            <template v-slot:activator="{ on, attrs }">
              <div v-on:click="commentClick" v-bind="attrs" v-on="on" class="text-center">
                {{getTimeDifference}}
              </div>
            </template>
            <span>{{comment.createdat | isoToDatetime}}</span>
          </v-tooltip>

          <v-menu
            v-if="$canControlPodService()"
            v-model="showingContextMenu"
            v-bind:close-on-content-click="false"
            offset-y left bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon color="primary" depressed v-bind="attrs"  v-on="on" data-cy="commentItem-actions-btn">
                <v-icon small>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list width="180px" data-cy="commentItem-actions-list">
              <v-list-item @click="toggleReplyForm" data-cy="commentItem-actions-list-reply">
                <v-list-item-icon>
                  <v-icon small>mdi-reply-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t('routes.pod.menuComment.reply')}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="showEdit" @click="toggleEditForm" data-cy="commentItem-actions-list-edit">
                <v-list-item-icon>
                  <v-icon small>mdi-pencil-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t('routes.pod.menuComment.edit')}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <CommentDeleteDialog v-if="showDelete" v-bind:comment="comment">
                <v-list-item v-on:click="stub" data-cy="commentItem-actions-list-delete">
                  <v-list-item-icon>
                    <v-icon small>mdi-delete-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{$t('routes.pod.menuComment.delete')}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </CommentDeleteDialog>

            </v-list>
          </v-menu>
        </div>
      </div>

      <div
        class="pod-comment-content"
        v-bind:class="commentContentClass"
        v-on:click="commentClick"
        v-on:dblclick="commentDblClick"
        data-cy="commentItem-content"
      >
        {{comment.content}}
      </div>

      <!-- <div class="d-flex justify-space-between my-2">
        <div v-if="$canControlPodService()" class="d-flex">
          <div class="pt-2">
            <v-simple-checkbox
                v-model="selected"
                v-bind:value="comment.checked"
                v-on:click.prevent.stop="toggleCheck"
                dense
                class="pod-comment-checkbox"
                :disabled="checkProcessing"
            >
            </v-simple-checkbox>
          </div>
          <v-btn tile icon v-on:click="toggleLike" :disabled="likeProcessing">
            <v-icon small v-show="!isLiked">mdi-thumb-up-outline</v-icon>
            <v-icon small v-show="isLiked">mdi-thumb-up</v-icon>

            <span class="ml-1">{{comment.likesCount}}</span>
          </v-btn>
          <v-btn tile icon v-if="showAllReplys && childComments.length" v-on:click="toggleShowReplys">
            <v-icon small>mdi-unfold-less-horizontal</v-icon>
          </v-btn>
          <v-btn tile icon v-if="!showAllReplys && childComments.length" v-on:click="toggleShowReplys">
            <v-icon small>mdi-unfold-more-horizontal</v-icon>
          </v-btn>
        </div>

        <div v-if="$canControlPodService()">
          <v-btn tile icon v-on:click="toggleReplyForm" data-cy="commentItem-addReply">
            <v-icon small>mdi-reply-outline</v-icon>
          </v-btn>
          <v-btn v-if="showEdit" tile icon v-on:click="toggleEditForm" data-cy="commentItem-editComment">
            <v-icon small>mdi-pencil-outline</v-icon>
          </v-btn>

          <comment-delete-dialog v-if="showDelete" v-bind:comment="comment">
            <v-btn tile icon data-cy="commentItem-deleteComment">
              <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
          </comment-delete-dialog>
        </div>
      </div> -->
    </div>

    <PodCommentForm
      v-if="showEditForm"
      v-bind:key="true"
      v-bind:editComment="comment"
      v-on:visibility="showEditForm = $event"
      class="">
    </PodCommentForm>

    <PodCommentForm
      v-if="showReplyForm"
      v-bind:key="false"
      v-bind:replyComment="comment"
      v-on:visibility="showReplyForm = $event"
      class="">
    </PodCommentForm>

    <div v-show="showAllReplys">
      <div v-for="comm in childComments" v-bind:key="comm.id" class="d-flex pl-4">
        <div class="pt-4">
          <v-icon color="primary">mdi-subdirectory-arrow-right</v-icon>
        </div>
        <PodCommentItem v-bind:comment="comm" v-bind:level="level+1" class="flex-grow-1" data-cy="commentItem-replyForm">
        </PodCommentItem>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import PodCommentForm from './PodCommentForm.vue'
import CommentDeleteDialog from './CommentDeleteDialog.vue'

export default {
  name: 'PodCommentItem',
  components: {
    PodCommentForm,
    CommentDeleteDialog,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    }
  },
  data: function() {
    return {
      selected: false,
      showAllReplys: true,
      showReplyForm: false,
      showEditForm: false,
      // inOutToggle: true,
      likeProcessing: false,
      checkProcessing: false,

      showingContextMenu: false,
    };
  },
  mounted: function() {
    this.selected = this.comment.checked;
  },
  methods: {
    stub: function() {
      this.showingContextMenu = false;
    },
    toggleReplyForm: function() {
      this.showReplyForm = !this.showReplyForm;
      this.showEditForm = false;

      if (this.showReplyForm) {
        this.closeOtherForms("reply");
      }

      this.showingContextMenu = false;
    },
    toggleEditForm: function() {
      this.showEditForm = !this.showEditForm;
      this.showReplyForm = false;

      if (this.showEditForm) {
        this.closeOtherForms("edit");
      }

      this.showingContextMenu = false;
    },
    // toggleInOut: function() {
    //   if (this.comment.end) {
    //     this.inOutToggle = !this.inOutToggle;
    //   }
    // },
    toggleShowReplys: function() {
      this.showAllReplys = !this.showAllReplys;
    },
    commentClick: function() {
      const SETSEEKTIMEEVENT = this.$store.getters.keywords.PLAYER.SETSEEKTIMEEVENT;
      this.$store.commit(SETSEEKTIMEEVENT, this.comment.start);
    },
    commentDblClick: function() {
      if (!this.showEdit) {
        return; // only the creator can edit the comment
      }

      this.toggleEditForm();
    },
    closeOtherForms: function(from) {
      const value = this.comment.id + from;
      const SETCLOSEFORMSEVENT = this.$store.getters.keywords.POD.COMMENT.SETCLOSEFORMSEVENT;
      this.$store.dispatch(SETCLOSEFORMSEVENT, value);
    },
    toggleLike: function() {
      if (this.likeProcessing || (!this.comment.likedBy || !Array.isArray(this.comment.likedBy))) {
        return;
      }

      // Prevent multiple clicks.
      this.likeProcessing = true;
      setTimeout(function() {
        this.likeProcessing = false;
      }.bind(this), 3000);

      const data = {
        podId: this.comment.podid,
        payload: {
          commentId: this.comment.id,
          userId: this.user.id,
        },
      }

      if (this.isLiked) {
        const DISLIKECOMMENT = this.$store.getters.keywords.POD.COMMENT.DISLIKECOMMENT;
        this.$store.dispatch(DISLIKECOMMENT, data)
          .then(function() {
            console.log('Dislike');
          });
      } else {
        const LIKECOMMENT = this.$store.getters.keywords.POD.COMMENT.LIKECOMMENT;
        this.$store.dispatch(LIKECOMMENT, data)
            .then(function() {
              console.log('Like');
            });
      }
    },
    toggleCheck: function() {
      if (this.checkProcessing) {
        return;
      }

      // Prevent multiple clicks.
      this.checkProcessing = true;
      setTimeout(function() {
        this.checkProcessing = false;
      }.bind(this), 3000);

      const data = {
        podId: this.comment.podid,
        payload: {
          commentId: this.comment.id,
          userId: this.user.id,
        },
      }

      if (this.comment.checked) {
        const UNCHECKCOMMENT = this.$store.getters.keywords.POD.COMMENT.UNCHECKCOMMENT;
        this.$store.dispatch(UNCHECKCOMMENT, data)
            .then(function() {
              this.selected = false;

              console.log('Uncheck');
            }.bind(this));
      } else {
        const CHECKCOMMENT = this.$store.getters.keywords.POD.COMMENT.CHECKCOMMENT;
        this.$store.dispatch(CHECKCOMMENT, data)
            .then(function() {
              this.selected = true;

              console.log('Check');
            }.bind(this));
      }
    },
  },
  computed: {
    showEdit: function() {
      if (!this.comment.createdby) return false;
      const commentCreator = this.comment.createdby.id;
      return (this.user.id === commentCreator);
    },
    showDelete: function() {
      if (!this.comment.createdby) return false;
      const commentCreator = this.comment.createdby.id;
      return (this.user.id === commentCreator);
    },
    user: function() {
      const GETDBUSER = this.$store.getters.keywords.AUTH.GETDBUSER;
      return this.$store.getters[GETDBUSER];
    },
    showCompact: function() {
      return !!this.comment.parentid;
    },
    childComments: function() {
      const GETCOMMENTS = this.$store.getters.keywords.POD.COMMENT.GETCOMMENTS;
      return this.$store.getters[GETCOMMENTS](this.comment.id);
    },
    playerCurrentTime: function() {
      const CURRENTTIME = this.$store.getters.keywords.PLAYER.CURRENTTIME;
      return this.$store.getters[CURRENTTIME];
    },
    commentContentClass: function() {
      return {
        // 'pod-comment-first-content': !this.showCompact,
        'pod-active-comment': !this.showCompact &&
            this.comment.start !== null &&
            (!this.showReplyForm && !this.showEditForm) &&
            (this.playerCurrentTime >= this.comment.start &&
            this.playerCurrentTime < Math.max(this.comment.end, this.comment.start + 1)),
      };
    },
    getPodCommentClasses() {
      return {
        'pod-comment-first-item': !this.showCompact,
        'pod-comment-active-header': this.showReplyForm || this.showEditForm,
      };
    },
    getTimeDifference: function() {
      if (!this.comment) {
        return "?";
      }

      let tsDiff = moment() - moment(this.comment.createdat);

      let years = moment.duration(tsDiff).years();
      if (years >= 1) {
        return this.$tc('common.time.years', years);
      }

      let months = moment.duration(tsDiff).months();
      if (months >= 1) {
        return this.$tc('common.time.months', months);
      }

      let weeks = moment.duration(tsDiff).weeks();
      if (weeks >= 1) {
        return this.$tc('common.time.weeks', weeks);
      }

      let days = moment.duration(tsDiff).days();
      if (days >= 1) {
        return this.$tc('common.time.days', days);
      }

      let hours = moment.duration(tsDiff).hours();
      if (hours >= 1) {
        return this.$tc('common.time.hours', hours);
      }

      return this.$tc('common.time.minutes', moment.duration(tsDiff).minutes());
    },
    isLiked: function() {
      let result = false;

      if (this.comment.likedBy) {
        result = this.comment.likedBy.includes(this.user.id);
      }

      return result;
    },
    getCreatedBy: function() {
      if (this.comment.createdby && this.comment.createdby.name) {
        return this.comment.createdby.name
      }

      if (this.comment.email) {
        return this.comment.email;
      }
      return "";
    }
  }
}
</script>

<style>
  .pod-comment-item {
    width: 100%;

  }

  .pod-comment-first-item {
    /* border-width: 0px 0px 2px 0px; */
    /* border-style: solid; */
    /* border-color: #000000; */
    border-radius: 10px;
    /* padding: 10px 14px; */
  }

  .theme--dark .pod-comment-first-item {
    background-color: #2f3640;
  }

  .theme--light .pod-comment-first-item {
    background-color: #f6f4f1;
  }

  .pod-comment-content {
    white-space: pre-line;
    word-break: break-word;
    /* background-color: #2E2E2E; */
  }
  /* .pod-comment-first-content {
    background-color: #2E2E2E;
  } */

  .pod-comment-active-header {
    border: 1px solid #FFC333;
    border-radius: 10px;

  }

  .pod-comment-active-header > div:first-child {
    color: #000000 !important;
    background-color: #FFC333 !important;
    border-radius: 10px 10px 0px 0px;
  }

  .pod-comment-active-header > div:first-child i {
    color: #000000 !important;
  }

  .pod-comment-inout-time {
    /* cursor: pointer; */
    display: inline-block;
    background-color: #FFFFFF;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 5px;
    color: #000000;
  }

  .pod-comment-inout-time > div > div:nth-child(2n) {
    font-weight: 600;
  }

  /* .pod-comment-inout-time > div:first-child,
  .pod-comment-inout-time > div:nth-child(4) {
    font-weight: 600;
    color: white;
  } */

  .pod-active-comment {
    color: #FFC333;
    /* color: #000000;  */
    /* background-color: #FFC333;  */
  }
  .pod-comment-checkbox i {font-size: 18px !important; margin-top: -2px;}
</style>
