const Helper = {
  generatePassword(pwdLength = 10) {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialChars = "!@#$%^&*";
    const digitChars = "0123456789";

    const randomChars = {
      lowercase: lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)],
      uppercase: uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)],
      special: specialChars[Math.floor(Math.random() * specialChars.length)],
      digit: digitChars[Math.floor(Math.random() * digitChars.length)],
    };

    // Initialize password with characters from each category
    let password = randomChars.lowercase + randomChars.uppercase + randomChars.special + randomChars.digit;

    // Fill remaining characters using combined character set
    const combinedChars = lowercaseChars + uppercaseChars + specialChars + digitChars;
    for (let i = 4; i < pwdLength; i++) {
      password += combinedChars[Math.floor(Math.random() * combinedChars.length)];
    }

    // Optionally shuffle the password to prevent predictability
    password = password.split("").sort(() => Math.random() - 0.5).join("");

    return password;
  },
  isFCPExtension: function() {
    return !!(window && window.webkit && window.webkit.messageHandlers);
  },

  isAdobeExtension: function() {
    return !!(window && window.cep);
  },

  getPasswordRules: function(vueInstance, options = { required: true, minLength: true, specialChars: true }) {
    const minPasswordLength = 8;
    const rules = [];

    if (options.required) {
      rules.push((v) => {
        if (typeof v !== 'string' || v.trim() === '') {
          return vueInstance.$t('menus.createUser.passwordRequired');
        }

        return true;
      });
    }

    if (options.minLength) {
      rules.push((v) => {
        if (options.required) {
          if (typeof v !== 'string' || v.trim().length < minPasswordLength) {
            return vueInstance.$t('menus.createUser.passwordLength');
          }
        } else {
          if (typeof v === 'string') {
            const value = v.trim();

            if (value.trim().length > 0 && value.length < minPasswordLength) {
              return vueInstance.$t('menus.createUser.passwordLength');
            }
          }
        }

        return true;
      });
    }

    if (options.specialChars) {
      rules.push((v) => {
        if (options.required) {
          if (typeof v !== 'string' || /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*&^%$#@!]).*$/.test(v) === false) {
            return vueInstance.$t('menus.createUser.passwordInvalidChars');
          }
        } else {
          if (typeof v === 'string') {
            const value = v.trim();

            if (value.length > 0 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[*&^%$#@!]).*$/.test(value) === false) {
              return vueInstance.$t('menus.createUser.passwordInvalidChars');
            }
          }
        }

        return true;
      });
    }

    return rules;
  },
}


export default Helper;
