import { API } from 'aws-amplify';

export default {
  getEmbedLinks: function(podID) {
    const options = {
        // headers, body parameters, query parameters
    };

    return API.get('app', 'pods/' + podID + '/embedlinks', options);
  },

  createEmbedLink: function(data) {
    const options = {
      body: {
        asset: data.asset
      }
    };

    return API.post('app','pods/' + data.podid + '/embedlinks', options);
  },

  deleteEmbedLink: function(podId, linkId) {
    const options = { };
    return API.del('app', 'pods/' + podId + '/embedlinks/' + linkId, options);
  },

  getEmbedlinkInfo: function(code) {
    const options = {};
    return API.get('app', 'embedded/' + code, options);
  }
}
