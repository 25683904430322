// import ... API
import Vue from 'vue';
import { Auth } from 'aws-amplify'
import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    user: null,
    dbUser: null,

    tenants: [],
    activeTenantId: window.localStorage.activetenantid || null,
    rootNodeId: window.localStorage.contextnodeid || null,

    forceLogout: false,
  },
  getters: {
    user: function (state) {
      return state.user;
    },
    dbUser: function (state) {
      return state.dbUser;
    },
    getTenants: function(state) {
      return state.tenants;
    },
    getActiveTenant: function(state) {
      return state.tenants.find(x => x.id === state.activeTenantId);
    },
    getActiveTenantId: function(state) {
      return state.activeTenantId;
    },
    getAccesspoints: function(state) {
      let accesspoints = [];

      for (const tenantItem of state.tenants) {
        for (const accesspoint of tenantItem.accessPoints) {
          accesspoints.push(accesspoint);
        }
      }

      return accesspoints;
    },
    getAccessPointIds: function(state, getters) {
      let accesspointIDs = [];
      for (const accesspoint of getters.getAccesspoints) {
        accesspointIDs.push(accesspoint.node.id);
      }
      return accesspointIDs;
    },
    getActiveAccesspoint: function(state, getters) {
      return getters.getAccesspoints.find(x => x.node.id === state.rootNodeId);
    },

    tenantIsExpired: function(state, getters) {
      if (getters?.getActiveTenant?.subscription?.subscriptionEnd) {
        if (getters.getActiveTenant.subscription.subscriptionEnd.minutes > 0) {
          return false;
        }

        return true;
      }

      return false;
    },
    subscriptionExpireTimestamp: function(state, getters) {
      if (getters.getActiveTenant &&
          getters.getActiveTenant.subscription &&
          getters.getActiveTenant.subscription.subscriptionEnd) {
        return parseInt(getters.getActiveTenant.subscription.subscriptionEnd.seconds * 1000);
      }
      return false;
    },

    forceLogout: function(state) {
      return state.forceLogout;
    },
    rootNodeId: function(state) {
      return state.rootNodeId;
    //   /*if (state?.dbUser?.groupnodes[0]?.nodeid) {
    //     return state.dbUser.groupnodes[0].nodeid;
    //   }*/

    //   console.log('state?.dbUser', state?.dbUser);
    //   console.log('root node ID', state?.dbUser?.tenants[0]?.accessPoint?.node?.id);

    //   if (state?.dbUser?.tenants[0]?.accessPoint?.node?.id) {
    //     return state?.dbUser?.tenants[0]?.accessPoint?.node?.id;
    //   }

    //   return null;
    },
  },
  mutations: {
    updateUser: function(state, user) {
      state.user = user;
    },
    updateDBUser: function(state, dbUser) {
      state.dbUser = dbUser;
    },
    setTenants: function(state, tenants) {
      state.tenants = tenants;
    },
    setActiveTenantId: function(state, tenantId) {
      window.localStorage.activetenantid = tenantId;
      state.activeTenantId = tenantId;
    },
    setRootNodeId: function(state, rootNodeId) {
      window.localStorage.contextnodeid = rootNodeId;
      state.rootNodeId = rootNodeId;
    },
    updateForceLogout: function(state, forceLogout) {
      state.forceLogout = forceLogout;
    },
    updateDBUserProperty: function(state, payload) {
      if (state.dbUser && state.dbUser[payload.property]) {
        state.dbUser[payload.property] = payload.value;
      }
    },
    updateUserSettingValue: function(state, payload) {
      if (state.dbUser && state.dbUser.settings && (payload.name in state.dbUser.settings)) {
        state.dbUser.settings[payload.name] = payload.value.toString();
      }
    },
  },
  actions: {
    signIn: function(context, loginParams) {
      return Auth.signIn(loginParams.email, loginParams.password, loginParams.options)
      .then(function(user) {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          user.vbTempPassword = loginParams.password;
          context.commit('updateUser', user);
        } else {
          context.commit('updateUser', user);

          // const REFRESHACCESSPOINTS = context.rootGetters.keywords.NODE.REFRESHACCESSPOINTS;
          // context.dispatch(REFRESHACCESSPOINTS, null, {root: true});
          // permissions are refreshed on mount of MainLayout
          // notifications are configured on mount of MainLayout
          // It's because on page refresh there is not SignIn action triggered
        }

        if (window.webkit && window.webkit.messageHandlers) {
          const userObject = JSON.stringify({action: "setUser", userID: user.uuid});
          window.webkit.messageHandlers.setUser.postMessage(userObject);
        }

        return user;
      });
    },

    signOut: function(context) {
      // unsubscribe from notifications
      const UNSUBSCRIBE = context.rootGetters.keywords.NOTIFICATION.UNSUBSCRIBE;

      return Auth.signOut()
      .then(function () {
        context.commit('updateUser', null);
        context.dispatch(UNSUBSCRIBE, null, {root: true});

        if (window.webkit && window.webkit.messageHandlers) {
          const signOutObject = JSON.stringify({action: "signOutUser"});
          window.webkit.messageHandlers.signOutUser.postMessage(signOutObject);
        }
      });
    },

    confirmSignUp: function(context, params) {
      return Auth.confirmSignUp(params.email, params.code);
    },

    setupTOTP: function(context, user) {
      return Auth.setupTOTP(user);
    },
    verifyTOTPToken: function(context, payload) {
      return Auth.verifyTotpToken(payload.user, payload.oneTimeCode);
    },
    enableTOTPMFA: function() {
      return Auth.currentAuthenticatedUser()
      .then(function(user) {
        return Auth.setPreferredMFA(user, 'TOTP');
      });
    },
    disableTOTPMFA: function() {
      return Auth.currentAuthenticatedUser()
      .then(function(user) {
        return Auth.setPreferredMFA(user, 'NOMFA');
      });
    },
    confirmTOTP: function(context, code) {
      const GETUSER = context.rootGetters.keywords.AUTH.GETUSER;
      const REFRESHCOGNITO = context.rootGetters.keywords.AUTH.REFRESHCOGNITO;

      let user = context.rootGetters[GETUSER];
      let copiedUser = Vue.util.extend({}, user)
      Vue;

      return Auth.confirmSignIn(copiedUser, code, 'SOFTWARE_TOKEN_MFA')
      .then(function() {
        return context.dispatch(REFRESHCOGNITO, null, {root: true});
      })
      .then(function(updatedUser) {
         context.commit('updateUser', updatedUser);
         return updatedUser;
      })
      .finally(function() {
        console.log ("FINALLY!!!")
      })
    },

    refreshCognitoUser: function(context) {
      Auth.currentSession()
      .then(function(result) {
        if (window.webkit && window.webkit.messageHandlers) {
          let idToken = result.idToken.jwtToken;
          let accessToken = result.accessToken.jwtToken;
          const sessionObject = JSON.stringify({action: "refreshUser", idToken: idToken, accessToken: accessToken});
          window.webkit.messageHandlers.refreshUser.postMessage(sessionObject);
        }
      })
      .catch(function() {
        // user not authenticated...
        if (window.webkit) {
          delete window.webkit;
        }
      });

      return Auth.currentAuthenticatedUser()
      .then(function(user) {
        if (!user || !user.signInUserSession) {
          throw "User not authenticated!";
        }

        if (window.webkit && window.webkit.messageHandlers) {
          const userObject = JSON.stringify({action: "setUser", userID: user.attributes.sub});
          window.webkit.messageHandlers.setUser.postMessage(userObject);
        }

        context.commit('updateUser', user);
        return user;
      });
    },
    refreshDBUser: function(context) {
      return Api.user.getMe()
      .then(function(response) {
        // console.log(response.data);

        // let firstTenantData = null;
        // if (response.data &&
        //     response.data.tenants &&
        //     response.data.tenants.length > 0
        // ) {
        //   firstTenantData = response.data.tenants[0];

        //   // store the context node and use it later to evaluate the permissions
        //   // the context node id is also updated in the interceptors.js file
        //   // window.localStorage.contextnodeid = response.data.tenants[0].accessPoints[0].node.id;
        // }

        // The user's abilities are stored in the tenant's access point group.
        // It does not come with the user object anymore.
        const user = response.data.user;
        // user.abilities = firstTenantData.accessPoint.group.abilities;
        // user.abilities = response.data.tenants[0].accessPoints[0].group.abilities;
        // user.tenants = response.data.tenants;

        // store the abilities in the local storage
        // they are loaded up in App.vue
        // and then refreshed in MainLayout and ConsoleLayout
        // window.localStorage.abilities = JSON.stringify(response.data.user.abilities);
        window.localStorage.settings = JSON.stringify(response.data.user.settings);
        window.localStorage.languageInterface = response.data.user.settings.language;

        context.commit('updateDBUser', response.data.user);
        context.commit('setTenants', response.data.tenants);

        return user;
      })
      .catch(function(e) {
        console.log("Error while refreshing DB user information:", e);
      });
    },
    saveSettings: function(context, payload) {
      const userID = context.getters.dbUser.id;

      return Api.user.saveSettings(userID, payload)
      .then(function() {
        return context.dispatch('refreshDBUser');
      });
    },

    getCurrentSession: function() {
      return Auth.currentSession();
    },

    forgotPassword: function(context, email) {
      return Auth.forgotPassword(email);
    },
    forgotPasswordSubmit: function(context, params) {
      return Auth.forgotPasswordSubmit(params.email, params.code, params.password);
    },
    changePassword: function(context, params) {
      return Auth.currentAuthenticatedUser()
      .then(function(user) {
        return Auth.changePassword(user, params.oldPassword, params.newPassword);
      });
    },
    completePassword: function(context, params) {
      // using this to fight the dumb amplify flow...
      return Auth.signIn(params.email, params.oldPassword, params.options)
      .then(function(user) {
        return Auth.completeNewPassword(user, params.password, {})
      })
      .then(function(user) {
        context.commit('updateUser', user);
        const REFRESHACCESSPOINTS = context.rootGetters.keywords.NODE.REFRESHACCESSPOINTS;
        context.dispatch(REFRESHACCESSPOINTS, null, {root: true});

        return user;
      });
    },

    setRootNodeId: function(context, nodeId) {
      if (!context.getters.getAccessPointIds || context.getters.getAccessPointIds.indexOf(nodeId) < 0) {
        return;
      }

      for (let i = 0; i < context.getters.getTenants.length; i++) {
        for (let o = 0; o < context.getters.getTenants[i].accessPoints.length; o++) {
          if (context.getters.getTenants[i].accessPoints[o].node.id === nodeId) {
            context.commit('setActiveTenantId', context.getters.getTenants[i].id);
            context.commit('setRootNodeId', context.getters.getTenants[i].accessPoints[o].node.id);
            return;
          }
        }
      }
    },

    createFreeTrial: function(context, payload) {
      return Api.common.createFreeTrial(payload);
    },
    salesmanCreatesTenant: function(context, payload) {
      return Api.common.salesmanCreatesTenant(payload);
    },
    disableUser: function(context) {
      context.commit('updateForceLogout', true);
    },
  },
};
