import { API } from 'aws-amplify';

export default {
  getUsers: function(podId) {
    const options = {};
    return API.get('app', `pods/${podId}/reviewers/users`, options);
  },
  getReviewers: function(podId) {
    const options = {};
    return API.get('app', `pods/${podId}/reviewers`, options);
  },
  inviteReviewers: function(podId, payload) {
    const options = {
      body: payload,
    }

    return API.post('app', `pods/${podId}/reviewers/invite`, options);
  }
};
