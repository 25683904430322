import { API } from 'aws-amplify';

export default {
  getAccesslinksByPod: function(podID) {
    const options = {
        // headers, body parameters, query parameters
    };

    return API.get('app', 'pods/' + podID + '/accesslinks', options);
  },

  getAccesslinksByUser: function(userId) {
    const options = {};
    return API.get('app', `users/${userId}/accesslinks`, options);
  },

  getAccesslinksByNode: function(nodeId) {
    const options = {};
    return API.get('app', `nodes/${nodeId}/accesslinks`, options);
  },

  createAccesslink: function(data) {
    const options = {
      body: data
    };

    return API.post('app', 'pods/' + data.podid + '/accesslinks', options);
  },

  updateAccesslink: function(id, data) {
    const options = {
      body: data
    };

    return API.patch('app', 'pods/' + data.podid + '/accesslinks/' + id, options);
  },

  deleteAccesslink: function(podId, accessLinkId) {
    const options = { };
    return API.del('app', 'pods/' + podId + '/accesslinks/' + accessLinkId, options);
  },

  inviteAccesslink: function(payload) {
    const options = {
      body: {
        receiver: payload.receiver,
        note: payload.note,
      },
    };

    return API.post('app', 'pods/' + payload.podId + '/accesslinks/' + payload.linkId + '/invite', options);
  },

  // external api call
  getAccesslinkInfo: function(code) {
    const options = {};
    return API.get('app', 'reviewaccess/' + code, options);
  },

  // external api call
  authorizeAccesslink: function(data) {
    const options = {
      body: data,
    };
    return API.post('app', 'reviewaccess/authorize', options);
  },

  getAccesslinkPod: function(data) {
    const options = {
      headers: {
        'vb-access-token': data.accesstoken,
      }
    };

    return API.get('app', 'reviewaccess/files/info', options);
  },

  addComment: function(data) {
    const options = {
      headers: {
        'vb-access-token': data.accesstoken,
      },
      body: data.formData,
    };

    return API.post('app', 'reviewaccess/comments', options);
  },

  updateComment: function(data) {
    const options = {
      headers: {
        'vb-access-token': data.accesstoken,
      },
      body: data.formData,
    };

    return API.patch('app', 'reviewaccess/comments/' + data.id, options);
  },

  deleteComment: function(data) {
    const options = {
      headers: {
        'vb-access-token': data.accesstoken,
      },
    };

    return API.del('app', 'reviewaccess/comments/' + data.id, options);
  }
}
