<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
    style="z-index: 1100 !important;"
    >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="primary  d-flex justify-space-between">
        <span>
          {{$t("routes.node.FCPImport")}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text  v-if="assets.length == 0">
        <div class="error" data-cy="">
          {{$t("routes.node.FCPNoItems")}}
        </div>
      </v-card-text>

      <v-card-text>
        <div v-if="errorMessage" class="error"> {{ errorMessage }} </div>
      </v-card-text>

      <v-card-text>
        <li v-for="asset in this.assets" v-bind:key="asset.id">
          {{ asset.name }}
        </li>
      </v-card-text>

      <v-card-text class="text-center">
        <v-progress-circular v-if="loading"
            indeterminate
            color="primary">
        </v-progress-circular>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="addToFCP">{{$t('routes.node.FCPOk')}}</v-btn>
        <v-btn text @click="cancel">{{$t('routes.node.FCPCancel')}}</v-btn>
      </v-card-actions>
    </v-card>


  </v-dialog>
</template>

<script>
export default {
  name: "FinalCutProDialog",
  props: {
    assets: {
      type: Array,
      required: true
    },
  },
  data: function() {
    return {
      dialog: false,
      loading: false,
      errorMessage: "",
    }
  },
  mounted: function() {
  },
  methods: {
    addToFCP: function() {
        const urls = [];
        const names = [];
        const durations = [];
        const hasAudios = [];
        const hasVideos = [];
        const audioRates = [];
        const audioChanels = [];
        const frameDurations = [];
        const widths = [];
        const heights = [];
        const isNoVideoNoAudio = [];
        for (const asset of this.assets) {
          const url = asset.url;
          const name = asset.name;
          const duration = asset.duration ?? 0;
          const hasAudio = asset.audiobitrate ? 1 : 0;
          const hasVideo = asset.bitrate ? 1 : 0;
          const audioRate = asset.audiobitrate;
          const audioChanel = asset.audiochanels;
          let frameDuration = null;
          let widthHeight = null;
          let width = null;
          let height = null;
          if (hasVideo) {
            frameDuration = asset.duration / asset.framerate;
            widthHeight = asset.resolution.split('x');
            width = widthHeight[0];
            height = widthHeight[1];
          }
          const noVideoNoAudio = !hasAudio && !hasVideo;
          urls.push(url);
          names.push(name);
          durations.push(duration);
          hasAudios.push(hasAudio);
          hasVideos.push(hasVideo);
          audioRates.push(audioRate);
          audioChanels.push(audioChanel);
          frameDurations.push(frameDuration);
          widths.push(width);
          heights.push(height);
          isNoVideoNoAudio.push(noVideoNoAudio);
        }

        const namesSet = Array.from(new Set(names));
        if (namesSet.length < names.length) {
          this.errorMessage = this.$t('routes.node.FCPDownloadNotAllowed');
          return;
        }

        const downloadObject = JSON.stringify({action: "chooseFolder",
                                            names: names,
                                            downloadURLs: urls,
                                            durations: durations,
                                            hasAudios: hasAudios,
                                            hasVideos: hasVideos,
                                            audioRates: audioRates,
                                            audioChanels: audioChanels,
                                            frameDurations: frameDurations,
                                            widths: widths,
                                            heights: heights,
                                            isNoVideoNoAudio: isNoVideoNoAudio,
                                            });
        window.webkit.messageHandlers.fcpextension.postMessage(downloadObject);

        this.dialog = false;
    },
    cancel: function() {
      this.dialog = false;
      this.errorMessage = "";
      this.loading = false;
    },
  },
  computed: {

  }
}
</script>

<style>

</style>
