<template>
  <v-dialog
  v-model="dialog"
  persistent
  scrollable
  :fullscreen="$vuetify.breakpoint.xsOnly"
  :width="$vuetify.breakpoint.smAndUp ? 600 : 'auto'"
  style="z-index: 1100 !important;"
  >

    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <slot></slot>
      </div>
    </template>

    <v-card>
      <v-card-title class="primary d-flex justify-space-between">
        <span>
          {{$t('routes.node.adobePremiereAdd')}}
        </span>

        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div v-if="errorMessage" class="error"> {{ errorMessage }} </div>
      </v-card-text>

      <v-card-text  v-if="assets.length == 0">
        <div class="error" data-cy="">
          {{$t("routes.node.adobeDialogNoItems")}}
        </div>
      </v-card-text>

      <v-card-text>
        <li v-for="asset in assets" v-bind:key="asset.id">
          {{ asset.name }} {{ addCheckMark(asset.id) }}
        </li>
      </v-card-text>

      <v-card-text class="text-center">
        <v-progress-circular v-if="loading"
            indeterminate
            color="primary">
        </v-progress-circular>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!loading && showOK" text @click="addAllToPremierePro">{{$t('routes.node.adobePremiereOk')}}</v-btn>
        <v-btn text @click="cancel">{{$t('routes.node.adobePremiereCancel')}}</v-btn>
      </v-card-actions>
    </v-card>


  </v-dialog>
</template>

<script>
export default {
  name: "AdobePremiereDialog",
  props: {
    assets: {
      type: Array,
      required: true,
    }
  },
  data: function() {
    return {
      dialog: false,
      loading: false,
      errorMessage: "",
      assetsToImport: [],
      importedAssets: [],
      showOK: true,
      failedAssets: [],
    }
  },
  mounted: function() {
  },
  methods: {
    addAllToPremierePro: function() {

      if (this.assets.length == 0) {
        this.dialog = false;
        return;
      }

      const assetNames = this.assets.map((p) => p.name);
      const namesSet = Array.from(new Set(assetNames));
      if (namesSet.length < assetNames.length) {
        this.errorMessage = this.$t('routes.node.adobeDownloadNotAllowed');
        return;
      }

      // eslint-disable-next-line no-undef
      var csInterface = new CSInterface;

      this.dialog = false;
      const script = `chooseFolder()`;

      let self = this;

      csInterface.evalScript(script, (result) => {

        if (!result) {
          return;
        } else {

          self.assetsToImport = self.assets.map((a) => {
            return {
              name: a.name,
              id: a.id,
              url: a.url,
            }
          });

          self.loading = true;
          self.dialog = true;

          self.importAssets();
        }
      });
    },
    importAssets() {
      if (!this.assetsToImport || this.assetsToImport.length == 0) {
        this.loading = false;
        if (this.failedAssets.length > 0) {
          this.showOK = false;

          this.errorMessage = this.$t('routes.node.adobeExistingFiles');
          return;
        }
        let self = this;
        setTimeout(() => {
          self.dialog = false;
          self.errorMessage = "";
          self.importedAssets = [];
          self.showOK = true;
        }, 500);
        return;
      }
      const assetData = this.assetsToImport.shift();
      // eslint-disable-next-line no-useless-escape
      const assetNameReplaced = assetData.name.replace(/[\/\\":*?<>|]/g,'_');
      const nameScript = `createFilePath("${assetNameReplaced}")`;

      // eslint-disable-next-line no-undef
      var csInterface = new CSInterface;
      let self = this;
      // eslint-disable-next-line no-undef
       csInterface.evalScript(nameScript, (res) => {

          if (res == "Exists") {
            self.failedAssets.push(assetData.id);
            self.importAssets();
            return;
          }

          const filePath = `${res}`;

          const fs = window.cep_node.require('fs');
          const file = fs.createWriteStream(filePath);

          file.on('finish', function () {
            // eslint-disable-next-line no-undef
            var csInterface = new CSInterface;
            csInterface.evalScript(`importFile()`, (res) => {
              if (res == "Ready") {
                  self.importedAssets.push(assetData.id);
                  self.importAssets();
              } else {
                console.log("Something weird happened");
                self.showOK = false;
                self.loading = false;
                self.errorMessage = self.$t('routes.node.adobePremiereError');
              }
            });
          });

          fetch(assetData.url).then(response => {
            const reader = response.body.getReader();

            self.readerRepeat(reader, file, filePath, assetData.id);

          }).catch(()=>{
            ///Exception occured do something
            self.dialog = true;
            self.showOK = false;
            self.loading = false;
            self.errorMessage = this.$t('routes.node.adobePremiereError');
          });

      });


    },
    addCheckMark(assetId) {
      const imported = this.importedAssets.filter((i) => i == assetId);
      if (imported.length > 0) {
        return " ✓";
      }
      const failed = this.failedAssets.filter((i) => i == assetId);
      if (failed.length > 0) {
        return " ✗";
      }
      return "";
    },
    readerRepeat: function (reader, file, filePath, podId) {
      let self = this;
      reader.read().then(object => {
        if (object.done) {
          file.end();
        } else {
          file.write(object.value, 'utf8', () => {
            self.readerRepeat(reader, file, filePath, podId);
          });
        }
      }).catch((exc)=>{
        ///Exception occured do something
        console.log("Exception: ", exc);
        self.showOK = false;
        self.loading = false;
        self.errorMessage = this.$t('routes.node.adobePremiereError');
      });
    },
    cancel: function() {
      this.assetsToImport = [];
      this.importedAssets = [];
      this.failedAssets = [];
      this.dialog = false;
      this.errorMessage = "";
      this.loading = false;
      this.showOK = true;
    },
  },
  computed: {

  }
}
</script>

<style>

</style>
