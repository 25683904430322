import { API } from 'aws-amplify';

export default {
  fetchContent: function(podId, assetId) {
    const options = {};

    return API.get('app', '/pods/' + podId + '/subtitles/' + assetId, options);
  },

  renameSubtitleAsset: function(data) {
    const options = {
      body: data.payload,
    };

    return API.patch('app', '/pods/' + data.podId + '/subtitles/' + data.assetId + '/rename', options);
  },

  deleteSubtitleAsset: function(data) {
    const options = {};

    return API.del('app', '/pods/' + data.podId + '/subtitles/' + data.assetId, options);
  },
};
