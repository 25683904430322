import { API } from 'aws-amplify';

export default {
  onBoardTenant: function(params) {
    const options = {
        // headers, body parameters, query parameters
        body: params
    };

    return API.post('app', 'onboard', options);
  },

  getTenantEmails: function(contextNodeId) {
    // contextNodeId = contextNodeId;
    const options = {};
    return API.get('app', `tenants/${contextNodeId}/emails`, options);
  },

  saveTenantEmails: function(contextNodeId, body) {
    const options = {body};
    return API.patch('app', `tenants/${contextNodeId}/emails`, options);
  },

  validateTenantEmail: function(tenantId, code) {
    const options = {
      body: {code},
    }
    return API.patch('app', `tenants/${tenantId}/email`, options);
  },

  getSubscription: function(contextNodeId) {
    const options = {};
    return API.get('app', 'tenants/'+contextNodeId+'/subscription', options);
  },

  subscribeForPlan: function(tenantId, params) {
    const options = {
      body: params,
    };

    return API.post('app', 'tenants/'+tenantId+'/subscribe', options);
  },

  buyAddons: function(tenantId, params) {
    const options = {
      body: params,
    };

    return API.post('app', 'tenants/'+tenantId+'/addons', options);
  },

  updateTenantMFA: function(payload) {
    const options = {
      body: {
        mfarequired: payload.mfarequired
      }
    };

    return API.patch('app', `tenants/${payload.accessPointId}/mfa`, options);
  },

  requestAccountDelete: function(payload) {
    const options = {
      body: payload
    };

    return API.post('app', 'tenants/accountdelete', options);
  },

  manageSubscriptionUrl: function(accessPointId) {
    const options = {
      body: {
        redirecturl: document.location.href,
      }
    }

    return API.post('app', `tenants/${accessPointId}/stripe`, options);
  },

  getBrandingInfo: function(nodeid) {
    const options = {};
    return API.get('app', 'nodes/'+nodeid+'/brand', options);
  },
  saveBrandingInfo: function(nodeid, body) {
    let formData = new FormData();

    for ( let key in body ) {
        formData.append(key, body[key]);
    }

    const options = {
      // headers, body parameters, query parameters
      headers: {
        "Content-Type": "multipart/form-data; boundary=file",
      },
      body: formData
    };

    return API.patch('app', 'nodes/'+nodeid+'/brand', options);
  },
  deleteBrandLogo: function(nodeid) {
    const options = {};
    return API.del('app', 'nodes/'+nodeid+'/brand/logo', options);
  }
}
