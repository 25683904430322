import Api from '../../api/Api'

export default {
  namespaced: true,
  state: {
    dbLanguages: [],
  },
  getters: {
    nodeTypes: function() {
      return {
        FOLDER: 1,
        PROJECT: 2,
        CLIENT: 3,
        TENANT: 4,
      };
    },
    projectStatuses: function() {
      return {
        LOGGING: 1,
        EDITING: 2,
        REVIEWING: 3,
        DELIVERED: 4,
      };
    },
    podStates: function() {
      return {
        ERROR: 0,
        PENDING: 1,
        PROCESSING: 2,
        COMPLETE: 3
      };
    },
    assetStates: function() {
      return {
        ERROR: 0,
        PENDING: 1,
        PROCESSING: 2,
        COMPLETE: 3
      };
    },
    podBadgeStatuses: function() {
      return {
        NOTSELECTED: 0,
        NEW: 1,
        DRAFT: 2,
        PRODUCTION: 3,
        REVIEW: 4,
        CORRECTION: 5,
        FINAL: 6,
      };
    },
    archiveStatus: function() {
      return {
        NOTARCHIVED: 0,
        ARCHIVED: 1,
        PENDING: 2,
        ARCHIVING: 3,
        UNARCHIVING: 4,
      };
    },
    dbLanguages: function(state) {
      return state.dbLanguages;
    },
    interfaceLanguages: function() {
      return [
        {id: 1, name: "English", code: 'en'},
        {id: 2, name: "Dutch", code: 'nl'},
      ];
    },
    assetTypes: function() {
      return {
        RAW: 0,
        THUMBNAIL: 1,
        WEB: 2,
        SPEECHTOTEXT: 3,
        AUDIO: 4,
        LIVEPREVIEW: 5,
        VTT: 6,
        SRT: 7,
        EXTERNAL: 8,
        FRAME: 9,
        IMAGE: 10,
        VIDEO: 11,
        OTHER: 12,
      };
    },
    userTypes: function() {
      return {
        TEAM_MEMBER: '5093fd3d-5a15-41ae-ac47-cedce16dc983',
        CLIENT: 'daa9eb20-9e46-4806-8a21-fc361197a170',
      };
    },
    rootFolderId: function() {
      return 'd87e9fab-044c-432f-b1f6-6fb77ef4dc22';
    }
  },
  mutations: {
    setDBLanguages: function(state, languages) {
      state.dbLanguages = languages;
    }
  },
  actions: {
    downloadThings: function(context, payload) {
      // payload = {nodeids, podids, excludeAssets, "id-token"}
      return Api.common.downloadThings(payload);
    },
    sendSupportTicket: function(context, payload) {
      return Api.common.sendSupportTicket(payload);
      // .then(function(response) {
      //   console.log("SEND Support ticket response", response);
      //   return;
      // });
    },
    sendFeatureRequest: function(context, payload) {
      return Api.common.sendFeatureRequest(payload);
      // .then(function(response) {
      //   console.log("Send feature request response", response);
      //   return;
      // });
    },
    refreshLanuages: function(context) {
      return Api.common.getLanguages()
      .then(function(response) {
        // console.log("Got db languages", response);
        context.commit("setDBLanguages", response.data);
      });
    },
  }
}
