import { API } from 'aws-amplify';

export default {
  searchNodesAndPods: function(payload) {
    const options = {
        // headers, body parameters, query parameters
        queryStringParameters: {
          nodeid: payload.nodeid,
          q: payload.searchStr,
          zone: payload.searchZone,
          wholeWords: payload.wholeWords,
          allWords: payload.allWords,
        }
    };

    if (payload.searchZone === "files") {
      options.queryStringParameters.includesubnodes = payload.includeSubnodes ? 1 : 0;
      options.queryStringParameters.includeassets = payload.includeAssets ? 1 : 0;
    }

    if (payload.searchZone === "folders") {
      options.queryStringParameters.includesubnodes = payload.includeSubnodes ? 1 : 0;
    }

    if (payload.searchZone === "metadata") {
      options.queryStringParameters.subfolders = payload.includeSubfolders ? 1 : 0;
      options.queryStringParameters.comments = payload.includeComments ? 1 : 0;
      options.queryStringParameters.customlabels = payload.includeCustomLabels ? 1 : 0;
      options.queryStringParameters.mllabels = payload.includeGeneratedLabels ? 1 : 0;
      options.queryStringParameters.transcripts = payload.includeTranscripts ? 1 : 0;
    }

    return API.get('app', 'search', options);
  },
}
