import { API } from 'aws-amplify';

export default {
  getLabels: function(podID) {
    const options = {
        // headers, body parameters, query parameters
    };

    return API.get('app', 'pods/'+podID+'/labels', options);
  },

  createLabel: function(podID, payload) {
    const options = {
      body: payload,
    };

    return API.post('app', 'pods/'+ podID+'/labels', options);
  },

  updateLabel: function(podID, labelID, payload) {
    const options = {
      body: payload,
    };

    return API.patch('app', 'pods/' + podID + '/labels/' + labelID, options);
  },

  deleteLabel: function(podID, labelID) {
    const options = {};
    return API.del('app', 'pods/' + podID + '/labels/' + labelID, options);
  },

  getMlLabels: function(podID) {
    const options = {};
    return API.get('app', 'pods/'+podID+'/mllabels', options);
  },

  updateMlLabel: function(podID, labelID, payload) {
    const options = {
      body: payload,
    };

    return API.patch('app', 'pods/' + podID + '/mllabels/' + labelID, options);
  },

  deleteMlLabel: function(podID, labelID) {
    const options = {};
    return API.del('app', 'pods/' + podID + '/mllabels/' + labelID, options);
  },
}
