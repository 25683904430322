import { API } from 'aws-amplify';

export default {
  getNotifications: function() {
    const options = {
        // headers, body parameters, query parameters
    };

    return API.get('app', 'me/notifications', options);
  },

  getNotificationTopics: function() {
    const options = {};
    return API.get('app', 'me/notifications/info', options);
  }
}
