<template>
  <div>
    <div v-if="!pod">
      File not found!
    </div>

    <div v-if="pod">

      <div class="d-flex align-center">
        <div class="flex-grow-1">
          <h1>{{$t('routes.pod.labels')}}</h1>
        </div>

        <div class="mr-2">
          <LabelSelectedDialog v-if="$canRunPaidService() && (isVideoPod || isImagePod)" :pods="selectedPods">
            <v-btn color="primary" elevation=0>
              <v-icon>mdi-tag</v-icon>
              {{$t('routes.pod.newLabelSet')}}
            </v-btn>
          </LabelSelectedDialog>
        </div>

        <div class="">
          <ExportFCPXMLDialog>
            <v-btn color="primary" elevation=0>
              <v-icon>mdi-file-export</v-icon>
              {{$t('routes.pod.menuLabelsAndMetadata.exportFCPXML')}}
            </v-btn>
          </ExportFCPXMLDialog>
        </div>
      </div>

      <div>
        <!-- <v-select
          v-bind:label="$t('routes.pod.menuEditLabels.sources')"
          v-model="selectedSource"
          v-bind:rules="selectedSourceRules"
          v-bind:items="sources"
          v-on:change="updateSelectedLayer"
          item-text="name"
          item-value="id"
          required
          single-line
        ></v-select> -->

        <v-radio-group
          v-model="selectedSource"
          @change="updateSelectedLayer"
          hide-details
          mandatory
          class="mb-4"
        >

          <v-radio
            v-for="source in sources"
            :key="source.id"
            :label="source.name"
            :value="source.id"
            data-cy="-----">
          </v-radio>
        </v-radio-group>
      </div>

      <!-- ML labels options starts -->
      <v-expansion-panels v-if="isMlLabelSelected" hover accordion class="pod-labels-options mb-4">
        <v-expansion-panel>
          <v-expansion-panel-header color="secondaryButtons">
            Options
          </v-expansion-panel-header>
          <v-expansion-panel-content class="py-4">

            <div v-if="selectedSource == 'amazon-text-source'" class="d-flex justify-center">
              <v-radio-group
                v-model="mlShowType"
                v-on:change="layersOptionsChanged"
                dense hide-details row
                class="mt-0 pt-0 mb-2"
              >
                <v-radio
                  v-bind:label="$t('routes.pod.menuEditLabels.showLines')"
                  value="lines"
                ></v-radio>
                <v-radio
                  v-bind:label="$t('routes.pod.menuEditLabels.showWords')"
                  value="words"
                ></v-radio>
                <v-radio
                  v-bind:label="$t('routes.pod.menuEditLabels.showBoth')"
                  value="both"
                ></v-radio>
              </v-radio-group>
            </div>

            <div v-if="selectedSource == 'amazon-text-source' || selectedSource == 'amazon-labels-source'" class="d-flex">
              <div class="text-right" style="width: 50%; padding-top: 10px; padding-right: 20px;">
                {{$t('routes.pod.menuEditLabels.confidenceLevel')}}
              </div>
              <div class="" style="width: 50%;">
                <v-text-field
                  type="number"
                  v-model.number="confidenceLevel"
                  v-bind:disabled="false"
                  v-on:blur="confidenceFocusOut"
                  v-bind:rules="confidenceLevelRules"
                  width="100%"
                  max="100" min="0"
                  required dense
                  hide-details
                  solo flat
                  data-cy="podMenu-labels-------">
                </v-text-field>
              </div>
            </div>

            <div v-if="(selectedSource == 'amazon-text-source' || selectedSource == 'amazon-labels-source') && isVideoPod " class="d-flex flex-row">
              <div class="flex-grow-1 d-flex justify-center">
                <v-checkbox
                  v-model="showLabelsOnLayer"
                  v-bind:label="$t('routes.pod.menuEditLabels.showTextLabels')"
                  v-on:change="layersOptionsChanged"
                  dense
                  hide-details
                >
                </v-checkbox>
              </div>

              <div class="flex-grow-1 d-flex justify-center">
                <v-checkbox
                  v-model="showRectOnLayer"
                  v-bind:label="$t('routes.pod.menuEditLabels.showRectangles')"
                  v-on:change="layersOptionsChanged"
                  dense
                  hide-details
                >
                </v-checkbox>
              </div>
            </div>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- ML labels options ends -->

      <div v-if="!openEditForm && !isMlLabelSelected" class="mb-4">
        <v-btn @click="openEditForm = true" elevation=0 color="primary" >
          <v-icon>mdi-tag-plus</v-icon>
          Add label
        </v-btn>
      </div>

      <PodEditLabelForm
        v-show="$canControlPodService() && (openEditForm || editItem)"
        v-bind:pod="pod"
        v-bind:editItem.sync="editItem"
        v-bind:isMlLabel="isMlLabelSelected"
        v-on:closeForm="closeForm()"
        data-cy="podMenu-labels-form"
        >
      </PodEditLabelForm>


      <PodCustomLabelsList
        v-if="selectedSource == 'custom-source'"
        v-model="chipsModel"
        v-bind:pod="pod"
        v-bind:labelsArray.sync="getCustomLabels"
        v-on:setEditItem="setEditItem"
        class=""
        data-cy="podMenu-labels-labelList"
      >
      </PodCustomLabelsList>

      <PodMlLabelsList
        v-if="selectedSource == 'amazon-text-source' || selectedSource == 'amazon-labels-source'"
        v-model="chipsModel"
        v-bind:pod="pod"
        v-bind:labelsArray.sync="getFilteredMLLabels"
        v-bind:mlShowType="mlShowType"
        v-bind:confidenceLevel="confidenceLevel"
        v-bind:disableTypeFilter="selectedSource == 'amazon-labels-source'"
        v-bind:disableEditing="selectedSource == 'amazon-labels-source'"
        v-on:setEditItem="setEditItem"
        class=""
        data-cy="podMenu-labels-generatedLabelList"
      >
      </PodMlLabelsList>

    </div>
  </div>
</template>

<script>

import LabelSelectedDialog from "../../../../../routes/pods2/components/dialogs/LabelSelectedDialog.vue"
import PodCustomLabelsList from './PodCustomLabelsList.vue'
import PodMlLabelsList from './PodMlLabelsList.vue'
import PodEditLabelForm from './PodEditLabelForm.vue'
import ExportFCPXMLDialog from './ExportFCPXMLDialog.vue'

export default {
  name: "LabelsToolbar",
  components: {
    LabelSelectedDialog,
    PodCustomLabelsList,
    PodMlLabelsList,
    PodEditLabelForm,
    ExportFCPXMLDialog,
  },
  data() {
    return {
      editItem: null,
      chipsModel: null,
      openEditForm: false,

      selectedSource: 'custom-source',
      selectedSourceRules: [
        v => !!v || this.$t('routes.pod.menuEditLabels.sourceRequired'),
      ],

      mlShowType: 'lines',
      confidenceLevel: 90,
      confidenceLevelRules: [
        // v => v.match(/^\d{1,3}$/gmi),
      ],

      showLabelsOnLayer: true,
      showRectOnLayer: true,

    };
  },
  mounted() {
    if (!this.pod) {
      return;
    }

    const REFRESHLABELS = this.$store.getters.keywords.POD.LABEL.REFRESHLABELS;
    this.$store.dispatch(REFRESHLABELS, this.pod.id);

    const REFRESHMLLABELS = this.$store.getters.keywords.POD.LABEL.REFRESHMLLABELS;
    this.$store.dispatch(REFRESHMLLABELS, this.pod.id);

    this.setControlsFromStash();

    const queryService = this.$route.query.service
    if (queryService) {
      for (let i = 0; i < this.sources.length; i++) {
        // check if the service is allowed
        if (this.sources[i].id == 'amazon-labels-source' && queryService == 'aws-detecting-labels') {
          this.selectedSource = 'amazon-labels-source';
          this.updateSelectedLayer();
          break;
        }

        if (this.sources[i].id == 'amazon-text-source' && queryService == 'aws-detecting-text') {
          this.selectedSource = 'amazon-text-source';
          this.updateSelectedLayer();
          break;
        }
      }
    }

  },
  watch: {
    selectedSource: function(newValue) {
      let REFRESHVERB = '';

      switch (newValue) {
        case 'custom-source': REFRESHVERB = this.$store.getters.keywords.POD.LABEL.REFRESHLABELS; break;
        case 'amazon-text-source':
        case 'amazon-labels-source':
          REFRESHVERB = this.$store.getters.keywords.POD.LABEL.REFRESHMLLABELS;
          break;
        default: break;
      }

      if (REFRESHVERB) {
        this.$store.dispatch(REFRESHVERB, this.pod.id);
      }


      // this.editItem = null;
      // this.chipsModel = null;
      // this.openEditForm = false;
      this.closeForm();
      this.setControlsFromStash();
      this.layersOptionsChanged();
    },
    getActiveLayer: function(newLayerID) {
      if (newLayerID == 2 && this.getAmazonDetectedTexts) {
        this.selectedSource = 'amazon-text-source';
      }

      if (newLayerID == 3 && this.getAmazonDetectedLabels) {
        this.selectedSource = 'amazon-labels-source';
      }
    },

  },
  methods: {
    closeForm: function() {
      this.editItem = null;
      this.chipsModel = null;
      this.openEditForm = false;
    },
    setEditItem: function(item) {
      // if (this.editItem && this.editItem.id === item.id) {
      //   this.editItem = null;
      //   this.openEditForm = false;
      // } else {
        this.editItem = item;
        this.openEditForm = true;
      // }

      console.log("this.editItem", this.editItem);
      console.log("this.openEditForm", this.openEditForm);
    },
    confidenceFocusOut: function() {
      this.confidenceLevel = Number(this.confidenceLevel);
      if (isNaN(this.confidenceLevel) || this.confidenceLevel > 100 || this.confidenceLevel == "") {
        this.confidenceLevel = 100;
      } else if (this.confidenceLevel < 0) {
        this.confidenceLevel = 0;
      }

      this.layersOptionsChanged();
    },
    layersOptionsChanged: function() {
      let optionsName = 'AWSDETECTEDTEXT';
      if (this.selectedSource == 'amazon-labels-source') {
        optionsName = 'AWSDETECTEDLABELS';
      }

      const options = {};
      options[optionsName] = {
        showLabelsOnLayer: this.showLabelsOnLayer,
        showRectOnLayer: this.showRectOnLayer,
        filterByType: this.mlShowType,
        confidenceLevel: this.confidenceLevel,
      }

      const SETLAYERSOPTIONS = this.$store.getters.keywords.PLAYER.SETLAYERSOPTIONS;
      this.$store.commit(SETLAYERSOPTIONS, options);
    },

    updateSelectedLayer: function() {
      const SETACTIVELAYER = this.$store.getters.keywords.PLAYER.SETACTIVELAYER;

      switch (this.selectedSource) {
        case 'amazon-text-source': this.$store.dispatch(SETACTIVELAYER, 2); break;
        case 'amazon-labels-source': this.$store.dispatch(SETACTIVELAYER, 3); break;
        default: break;
      }
    },
    setControlsFromStash: function() {
      const GETLAYERSOPTIONS = this.$store.getters.keywords.PLAYER.GETLAYERSOPTIONS;
      const options = this.$store.getters[GETLAYERSOPTIONS];

      switch (this.selectedSource) {
        case 'amazon-text-source': this.setControlsForAWSDETECTEDTEXT(options); break;
        case 'amazon-labels-source': this.setControlsForAWSDETECTEDLABELS(options); break;
        default: this.setControlsForAWSDETECTEDTEXT(options);
      }
    },
    setControlsForAWSDETECTEDTEXT: function(options) {
      if (typeof options.AWSDETECTEDTEXT.showLabelsOnLayer == 'boolean') {
        this.showLabelsOnLayer = options.AWSDETECTEDTEXT.showLabelsOnLayer;
      }
      if (typeof options.AWSDETECTEDTEXT.showRectOnLayer == 'boolean') {
        this.showRectOnLayer = options.AWSDETECTEDTEXT.showRectOnLayer;
      }
      if (typeof options.AWSDETECTEDTEXT.filterByType == 'string') {
        this.mlShowType = options.AWSDETECTEDTEXT.filterByType;
      }
      if (typeof options.AWSDETECTEDTEXT.confidenceLevel == 'number') {
        this.confidenceLevel = options.AWSDETECTEDTEXT.confidenceLevel;
      }
    },
    setControlsForAWSDETECTEDLABELS: function(options) {
      if (typeof options.AWSDETECTEDLABELS.showLabelsOnLayer == 'boolean') {
        this.showLabelsOnLayer = options.AWSDETECTEDLABELS.showLabelsOnLayer;
      }
      if (typeof options.AWSDETECTEDLABELS.showRectOnLayer == 'boolean') {
        this.showRectOnLayer = options.AWSDETECTEDLABELS.showRectOnLayer;
      }
      if (typeof options.AWSDETECTEDLABELS.confidenceLevel == 'number') {
        this.confidenceLevel = options.AWSDETECTEDLABELS.confidenceLevel;
      }
    },

  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    isVideoPod: function() {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
    // isAudioPod: function() {
    //   const ISAUDIOPOD = this.$store.getters.keywords.POD.ISAUDIOPOD;
    //   return this.$store.getters[ISAUDIOPOD];
    // },
    isImagePod: function() {
      const ISIMAGEPOD = this.$store.getters.keywords.POD.ISIMAGEPOD;
      return this.$store.getters[ISIMAGEPOD];
    },

    getCustomLabels: function() {
      const GETLABELS = this.$store.getters.keywords.POD.LABEL.GETLABELS;
      return this.$store.getters[GETLABELS];
    },


    getMlLabels: function() {
      const GETMLLABELS = this.$store.getters.keywords.POD.LABEL.GETMLLABELS;
      return this.$store.getters[GETMLLABELS];
    },
    getFilteredMLLabels: function() {
      switch (this.selectedSource) {
        case 'amazon-text-source': return this.getAmazonDetectedTexts;
        case 'amazon-labels-source': return this.getAmazonDetectedLabels;
        default: return [];
      }
    },


    sources: function() {
      let services = [];
      services.push(this.getCustomSourceItem);

      if (this.getAmazonDetectedTexts &&
          this.getAmazonDetectedTexts.length)
      {
        services.push(this.getAmazonTextSource);
      }

      if (this.getAmazonDetectedLabels &&
          this.getAmazonDetectedLabels.length)
      {
        services.push(this.getAmazonLabelsSource);
      }

      return services;
    },
    getCustomSourceItem: function() {
      return {
        id: 'custom-source',
        name: this.$t('routes.pod.menuEditLabels.customLabels')
      };
    },
    getAmazonTextSource: function() {
      return {
        id: 'amazon-text-source',
        name: this.$t('routes.pod.menuEditLabels.amazonDetectedText')
      };
    },
    getAmazonLabelsSource: function() {
      return {
        id: 'amazon-labels-source',
        name: this.$t('routes.pod.menuEditLabels.amazonDetectedLabels')
      };
    },
    getAmazonDetectedTexts: function() {
      // filter the ML labels by service 'aws-detecting-text'
      return this.getMlLabels.filter(x => x.service === 'aws-detecting-text');
    },
    getAmazonDetectedLabels: function() {
      // filter the ML labels by service 'aws-detecting-labels'
      // return this.getMlLabels.filter(x => x.service === 'aws-detecting-labels');
      const GETCOMBINEDAWSDETECTEDLABELS = this.$store.getters.keywords.POD.LABEL.GETCOMBINEDAWSDETECTEDLABELS;
      return this.$store.getters[GETCOMBINEDAWSDETECTEDLABELS];
    },
    isMlLabelSelected: function() {
      return this.selectedSource == 'amazon-text-source' ||
             this.selectedSource == 'amazon-labels-source';
    },
    getActiveLayer: function() {
      const GETACTIVELAYER = this.$store.getters.keywords.PLAYER.GETACTIVELAYER;
      return this.$store.getters[GETACTIVELAYER];
    },
    selectedPods: function() {
      return [this.pod];
    },
  }
}
</script>

<style>
.pod-labels-options button.v-expansion-panel-header {
  border-radius: 10px;
}

.pod-labels-table { width: 100%; line-height: 1.2; border-collapse: collapse; }
.pod-labels-table > thead { background-color: #FFC333; border-radius: 8px; }
.pod-labels-table > thead > tr > td { font-weight: 600; color: #333333; padding: 8px;}
.pod-labels-table > thead > tr > td:first-child {
  border: 0px solid #FFC333;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.pod-labels-table > thead > tr > td:last-child {
  border: 0px solid #FFC333;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pod-labels-table > thead > tr > td:nth-child(1),
.pod-labels-table > tbody > tr > td:nth-child(1) { width: 20%;}
.pod-labels-table > thead > tr > td:nth-child(2),
.pod-labels-table > tbody > tr > td:nth-child(2) { width: 15%; text-align: center;}
.pod-labels-table > thead > tr > td:nth-child(3),
.pod-labels-table > tbody > tr > td:nth-child(3) { width: 65%;}

.theme--light .pod-labels-table > tbody > tr { background-color: #f6f4f1; border-bottom: 2px solid #FFFFFF;}
.theme--dark .pod-labels-table > tbody > tr { background-color: #2f3640; border-bottom: 2px solid #232b36;}
.pod-labels-table > tbody > tr > td { padding: 8px 8px; }
.pod-labels-table > tbody > tr > td:first-child {
  border: 0px solid #FFC333;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.pod-labels-table > tbody > tr > td:last-child {
  border: 0px solid #FFC333;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pod-labels-table > tbody > tr.active-subtitle { color: #f4a817; }
.pod-labels-table > tbody > tr > td > .pod-subtitle-warning { color: #ff8282; }

.labels-chevron {float:right;}

</style>