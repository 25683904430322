<template>
  <div v-show="getAccessPoints.length > 1">
    <v-menu v-model="isMenuVisible" :close-on-click="true" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn

          v-bind="attrs"
          v-on="on"
          color="primary"
          elevation="0"
          block
          rounded
        >
          <v-icon>
            mdi-home-circle
          </v-icon>

          <span v-if="getActiveAP">
            {{getActiveAP.node.name}}
          </span>
        </v-btn>
      </template>

      <v-list>
        <v-list-group
        v-for="tenant in getTenants"
        :key="tenant.id"
        no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="tenant.name"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
          v-for="accesspoint in tenant.accessPoints"
          :key="accesspoint.node.id"
          :disabled="accesspoint.node.id == getActiveAP.node.id"
          @click="setActiveAP(tenant, accesspoint)"
          class="pl-8"
          >
            <v-list-item-content>
              <v-list-item-title v-text="accesspoint.node.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: "AccesspointSwitch",
  data() {
    return {
      isMenuVisible: false,
    };
  },
  watch: {
    getActiveAP(accesspoint) {
      this.$updateAbilities(accesspoint.group.abilities);

      if (this.$route.path.startsWith('/console')) {
        const SETACTIVENODE = this.$store.getters.keywords.NODE.SETACTIVENODE;
        this.$store.dispatch(SETACTIVENODE, accesspoint.node.id);

        const RESETUSERS = this.$store.getters.keywords.USER.RESETUSERS;
        this.$store.commit(RESETUSERS);
      }
    }
  },
  methods: {
    setActiveAP(tenant, accesspoint) {
      const SETROOTNODEID = this.$store.getters.keywords.AUTH.SETROOTNODEID;
      this.$store.dispatch(SETROOTNODEID, accesspoint.node.id)

      this.isMenuVisible = false;

      if (this.$route.path.startsWith('/console')) {
        if (this.$route.path !== `/console/index`) {
          this.$router.push(`/console/index`);
        }

        return;
      }

      this.$router.push(`/node/${accesspoint.node.id}`);
    }
  },
  computed: {
    getTenants() {
      const GETTENANTS = this.$store.getters.keywords.AUTH.GETTENANTS;
      return this.$store.getters[GETTENANTS];
    },
    getActiveAP() {
      const GETACTIVEACCESSPOINT = this.$store.getters.keywords.AUTH.GETACTIVEACCESSPOINT;
      return this.$store.getters[GETACTIVEACCESSPOINT];
    },
    getAccessPoints() {
      const GETACCESSPOINTS = this.$store.getters.keywords.AUTH.GETACCESSPOINTS;
      return this.$store.getters[GETACCESSPOINTS];
    },
  },
}
</script>

<style>
</style>
