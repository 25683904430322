import { API } from 'aws-amplify';

export default {
  getUploadLinks: function(nodeid) {
    const options = {
    };

    return API.get('app', `nodes/${nodeid}/uploadlinks`, options);
  },

  getAllUploadlinks: function(userId, rootNodeId) {
    const options = {
      queryStringParameters: {
        apid: rootNodeId,
      }
    };

    return API.get('app', `users/${userId}/uploadlinks`, options);
  },

  createUploadLink: function(payload) {
    const options = {
      body: payload.formData
    };

    return API.post('app',`uploadlinks`, options);
  },

  updateUploadLink: function(id, formData) {
    const options = {
      body: formData
    };

    return API.patch('app', `uploadlinks/${id}`, options);
  },

  deleteUploadLink: function(id) {
    const options = { };
    return API.del('app', `uploadlinks/${id}`, options);
  },

  inviteUploadlink: function(payload) {
    const options = {
      body: {
        receiver: payload.receiver,
        note: payload.note,
      },
    };

    return API.post('app', 'uploadlinks/' + payload.linkId + '/invite', options);
  },

  // external api call
  getUploadAccessInfo: function(code) {
    const options = {};
    return API.get('app', `uploadaccess/${code}`, options);
  },

  // external api call
  authorizeUploadlink: function(data) {
    const options = {
      body: data,
    };
    return API.post('app', 'uploadaccess/authorize', options);
  },

  checkStorage: function(accesstoken, units) {
    const options = {
      headers: {
        'vb-upload-token': accesstoken,
      },
    };
    return API.get('app', 'uploadaccess/resources/storage?units='+units, options);
  },

  createPod: function(data, accesstoken) {
    const options = {
      headers: {
        'vb-upload-token': accesstoken,
      },
      body: data,
    }

    return API.post('app', 'uploadaccess/files/upload', options);
  },

  getNodeInfo: function(data) {
    const options = {
      headers: {
        'vb-upload-token': data.accesstoken,
      }
    };

    return API.get('app', 'uploadaccess/folders/info', options);
  },
}
